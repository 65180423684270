import React from "react";
import styles from "./CategoryView.module.css";
import type { Category } from "@/app/types/lambda-types";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBook } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
type Props = {
  category: Category;
};

const Category = ({ category }: Props) => {
  return (
    <div className={styles.category}>
      <div className={styles.title}>
        <FontAwesomeIcon icon={faBook as IconProp} />
        <Link className={styles.titleLink} href={`/knowledge-base/category/${category.uuid}`}>
          {category.name}
        </Link>
      </div>
      <div className={styles.articles}>
        {category.children && category.children.articles.map((child) => (
          <Link key={child.uuid} className={styles.article} href={`/knowledge-base/article/${child.uuid}`}>
            {child.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Category;
