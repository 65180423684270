import { Itinerary } from "@/app/types/lambda-types"
import styles from './ItineraryModal.module.css';
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/thin";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { formatDate } from "@/utils/configs";
import getOrdinal from "@/utils/data-manipulation/getOrdinal";

type Props = {
  itinerary?: Itinerary[],
  holidayCNUID: string,
  holidayName: string,
  clickPosition?: { x: number, y: number }
}

const ItineraryModal = ({ itinerary, holidayCNUID, holidayName, clickPosition }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const getScrollbarWidth = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
  };

  const lockScroll = () => {
    const scrollbarWidth = getScrollbarWidth();
    document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    document.body.classList.add('lock-scroll');
  };

  const unlockScroll = () => {
    document.body.classList.remove('lock-scroll');
    document.body.style.removeProperty('--scrollbar-width');
  };

  const handleClose = () => {
    if (dialogRef.current) {
      dialogRef.current.classList.add(styles.closing);
      dialogRef.current.addEventListener('animationend', () => {
        dialogRef.current?.close();
        dialogRef.current?.classList.remove(styles.closing);
        unlockScroll();
      }, { once: true });
    }
  };

  // Create a showModal function that we control
  const showModal = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
      lockScroll();
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const dialogDimensions = dialogRef.current?.getBoundingClientRect();
    if (dialogDimensions) {
      const clickedInDialog = e.clientY >= dialogDimensions.top &&
        e.clientY <= dialogDimensions.bottom &&
        e.clientX >= dialogDimensions.left &&
        e.clientX <= dialogDimensions.right;

      if (!clickedInDialog) {
        handleClose();
      }
    }
  };

  const getDate = (date: string, time: string): string => {
    const day = time ? formatDate(date + ' ' + time) : formatDate(date);
    const dayOfMonth = getOrdinal(day.date());
    return `${dayOfMonth} ${day.format('MMM YYYY')} at ${day.format('HH:mm')}`;
  };

  // Use MutationObserver to watch for the dialog's open attribute
  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
          if (dialog.hasAttribute('open')) {
            lockScroll();
          }
        }
      });
    });

    observer.observe(dialog, { attributes: true });

    return () => {
      observer.disconnect();
      unlockScroll(); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') handleClose();
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    if (dialogRef.current && clickPosition) {
      const translateX = clickPosition.x - (window.innerWidth / 2);
      const translateY = clickPosition.y - (window.innerHeight / 2);

      dialogRef.current.style.setProperty('--start-x', `${translateX}px`);
      dialogRef.current.style.setProperty('--start-y', `${translateY}px`);
    }
  }, [clickPosition]);

  return (
    <dialog
      id={`itinerary-modal-${holidayCNUID}`}
      ref={dialogRef}
      className={styles.modal}
      onClick={handleClick}
    >
      <button
        onClick={handleClose}
        className={styles.closeButton}
      >
        <FontAwesomeIcon icon={faXmark as IconProp} />
      </button>
      <h4>{holidayName}</h4>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Day</th>
            <th></th>
            <th>Arrives</th>
            <th>Departs</th>
          </tr>
        </thead>
        <tbody>
          {
            itinerary && itinerary?.length > 0 && itinerary.map((itineraryItem, index) => (
              <tr key={index}>
                <td>{itineraryItem.day}</td>
                <td>{itineraryItem.name}</td>
                <td>
                  {itineraryItem.arrive_time
                    ? getDate(itineraryItem.arrive_date, itineraryItem.arrive_time)
                    : '-'}
                </td>
                <td>
                  {itineraryItem.depart_time
                    ? getDate(itineraryItem.depart_date, itineraryItem.depart_time)
                    : '-'}
                </td>
              </tr>
            )) || <p>No itinerary available for this holiday.</p>
          }
        </tbody>
      </table>
    </dialog>
  );
}

export default ItineraryModal;