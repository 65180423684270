import React, { useState, useEffect } from 'react';
import styles from './Navigation.module.css'

type DayTime = {
  opening: string;
  closing: string;
};

type OpeningTimes = {
  Monday: DayTime;
  Tuesday: DayTime;
  Wednesday: DayTime;
  Thursday: DayTime;
  Friday: DayTime;
  Saturday: DayTime;
  Sunday: DayTime;
};

type InformationTimes = {
  sales: OpeningTimes;
  support: OpeningTimes;
};

interface OpeningHoursProps {
  openingTimes: InformationTimes | undefined;
  className: string;
  type?: 'sales' | 'support';
}

const OpeningHours: React.FC<OpeningHoursProps> = ({ openingTimes, type = 'sales', className }) => {
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const updateMessage = () => {
      if (!openingTimes) return;

      const now = new Date();
      const currentDay = now.getDay();
      const currentHour = now.getHours();
      
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;
      const today = days[currentDay];
      const tomorrow = days[(currentDay + 1) % 7];

      const todayTimes = openingTimes[type][today];
      const tomorrowTimes = openingTimes[type][tomorrow];

      // Check if closed today or tomorrow
      const todayIsClosed = todayTimes.opening === 'Closed' || todayTimes.closing === 'Closed';
      const tomorrowIsClosed = tomorrowTimes.opening === 'Closed' || tomorrowTimes.closing === 'Closed';

      // Parse opening and closing hours
      const todayOpeningHour = parseInt(todayTimes.opening?.split(':')[0]?.replace('am', '') || '0');
      const todayClosingHour = parseInt(todayTimes.closing?.split(':')[0]?.replace('pm', '') || '0') + 12;

      // Determine if currently open
      const isOpen = !todayIsClosed && currentHour >= todayOpeningHour && currentHour < todayClosingHour;

      // Generate appropriate message
      if (todayIsClosed && !tomorrowIsClosed) {
        setMessage(`Open from ${tomorrowTimes.opening} tomorrow`);
      } else if (todayIsClosed && tomorrowIsClosed) {
        setMessage('Closed for the holidays');
      } else if (isOpen) {
        setMessage(`CALL NOW: Open until ${todayTimes.closing} ${todayClosingHour >= 18 ? 'tonight' : 'today'}`);
      } else if (currentHour < todayOpeningHour) {
        setMessage(`Opening at ${todayTimes.opening} today`);
      } else if (tomorrowIsClosed) {
        setMessage('Closed for the holidays');
      } else {
        setMessage(`Open from ${tomorrowTimes.opening} tomorrow`);
      }
    };

    updateMessage();
    // Update message every minute
    const interval = setInterval(updateMessage, 60000);
    return () => clearInterval(interval);
  }, [openingTimes, type]);

  return (
    <p className={styles[className]}>
      {message}
    </p>
  );
};

export default OpeningHours;