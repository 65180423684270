import React from 'react';
import styles from './SearchBar.module.css';

type Port = {
  label: string;
  value: string | null;
  selected?: boolean;
};

type Destination = {
  label: string;
  value: string;
  selected?: boolean;
};

interface DestinationDropdownProps {
  destinations: Destination[];
  startingPorts: Port[];
  visitingPorts: Port[];
  selectedDestination: Destination;
  selectedStartingPort: Port | null;
  selectedVisitingPort: Port | null;
  onDestinationChange: (destination: Destination) => void;
  onStartingPortChange: (port: Port) => void;
  onVisitingPortChange: (port: Port) => void;
}

const DestinationDropdown: React.FC<DestinationDropdownProps> = ({
  destinations,
  startingPorts,
  visitingPorts,
  selectedDestination,
  selectedStartingPort,
  selectedVisitingPort,
  onDestinationChange,
  onStartingPortChange,
  onVisitingPortChange
}) => {
  const handleStartingPortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === '' ? null : e.target.value;
    const selectedPort = startingPorts.find(port => port.value === value);
    if (selectedPort) {
      onStartingPortChange(selectedPort);
    }
  };

  const handleVisitingPortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === '' ? null : e.target.value;
    const selectedPort = visitingPorts.find(port => port.value === value);
    if (selectedPort) {
      onVisitingPortChange(selectedPort);
    }
  };

  return (
    <div className={styles.dropdownDestination}>
      <div className={styles.dropdownDropdownListOptions}>
        {destinations.map((dest, index) => (
          <button
            key={index}
            className={`${styles.listOption} ${selectedDestination.value === dest.value ? styles.listOptionSelected : ''
              }`}
            onClick={() => onDestinationChange(dest)}
          >
            {dest.label}
          </button>
        ))}
      </div>
      <div className={styles.advancedSearchInputGroups}>
        <div className={styles.advancedSearchInputGroup}>
          <div className={styles.advancedSearchStartingPort}>
            Start Port
            <select
              value={selectedStartingPort?.value ?? ''}
              onChange={handleStartingPortChange}
            >
              {startingPorts.map((port, index) => (
                <option key={index} value={port.value ?? ''}>
                  {port.label}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.advancedSearchVisitingPort}>
            Visiting Port
            <select
              value={selectedVisitingPort?.value ?? ''}
              onChange={handleVisitingPortChange}
            >
              {visitingPorts.map((port, index) => (
                <option key={index} value={port.value ?? ''}>
                  {port.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationDropdown;