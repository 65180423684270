import React from "react";
import { storyblokEditable } from "@storyblok/react";
import dayjs from "dayjs";
import type { SearchBarStoryblok } from "@/app/types/component-types-sb";
import SearchBar, {
  NullableSearchOptions,
  Destination,
  CruiseLine,
  Ship,
  DateOption,
  Port
} from "../../globals/search-bar";

const SearchBarComponent = ({ blok }: { blok: SearchBarStoryblok }) => {

  // Transform destination string to Destination object if exists
  const transformDestination = (destValue?: string): Destination | null => {
    if (!destValue || destValue === '') return null;

    const destinations = [
      { label: "Mediterranean", value: "7" },
      { label: "Caribbean", value: "2" },
      { label: "Far East", value: "5" },
      { label: "Transatlantic", value: "11" },
      { label: "Alaska", value: "13" },
      { label: "Dubai & Emirates", value: "23" },
      { label: "South America", value: "10" },
      { label: "Africa", value: "17" },
      { label: "Australasia", value: "14" },
      { label: "Baltic", value: "20" },
      { label: "Black Sea", value: "21" },
      { label: "Canaries", value: "1" },
      { label: "Central America", value: "3" },
      { label: "China", value: "24" },
      { label: "Europe", value: "4" },
      { label: "Egypt & Red Sea", value: "25" },
      { label: "Hawaii", value: "6" },
      { label: "Iberia", value: "22" },
      { label: "Middle East", value: "19" },
      { label: "North America", value: "8" },
      { label: "Pacific", value: "15" },
      { label: "Polar Regions", value: "18" },
      { label: "Scandinavia", value: "9" },
      { label: "United Kingdom", value: "16" },
      { label: "Worldwide", value: "12" }
    ];

    const dest = destinations.find(d => d.value === destValue);
    return dest || null;
  };

  // Transform cruise line string to CruiseLine object if exists
  const transformCruiseLine = (lineValue?: string): CruiseLine | null => {
    if (!lineValue || lineValue === '') return null;

    const cruiseLines = [
      { label: "MSC", value: "16" },
      { label: "Norwegian", value: "17" },
      { label: "Princess", value: "20" },
      { label: "Virgin Voyages", value: "734" },
      { label: "Costa", value: "9" },
      { label: "Celebrity", value: "3" },
      { label: "Royal Caribbean", value: "22" },
      { label: "Carnival", value: "8" },
      { label: "Holland America", value: "15" },
      { label: "AmaWaterways", value: "63" },
      { label: "Ambassador", value: "848" },
      { label: "Azamara", value: "66" },
      { label: "Celestyal", value: "495" },
      { label: "Crystal", value: "10" },
      { label: "Cunard", value: "5" },
      { label: "Disney", value: "73" },
      { label: "Explora Journeys", value: "849" },
      { label: "Fred Olsen", value: "13" },
      { label: "Marella", value: "28" },
      { label: "Oceania", value: "48" },
      { label: "P&O", value: "1" },
      { label: "Regent Seven Seas", value: "21" },
      { label: "Seabourn", value: "24" },
      { label: "Silversea", value: "25" }
    ];

    const line = cruiseLines.find(l => parseInt(l.value) === parseInt(lineValue));
    return line || null;
  };

  const transformShip = (shipId?: string): Ship | null => {
    if (!shipId) return null;
    return { label: '', value: shipId } as Ship;
  }

  // Transform date string to DateOption based on exact_date flag
  const transformDate = (dateValue?: string, exactDate?: boolean): DateOption | null => {
    if (!dateValue) return null;

    const date = dayjs(dateValue);

    if (exactDate) {
      // For exact date, include fullDate and format the label with ordinal
      const day = date.date();
      const getOrdinal = (n: number): string => {
        const s = ['th', 'st', 'nd', 'rd'];
        const v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      };

      return {
        label: `${getOrdinal(day)} ${date.format('MMM YYYY')}`,
        value: `${date.format('MM')}_${date.format('YYYY')}`,
        fullDate: date.format('YYYY-MM-DD')
      };
    } else {
      // For month selection, just use month and year
      return {
        label: `${date.format('MMMM YYYY')}`,
        value: `${date.format('MM')}_${date.format('YYYY')}`
      };
    }
  };

  // Transform port string to Port object if exists
  const transformPort = (portValue?: string): Port | null => {
    if (!portValue) return null;

    return {
      label: portValue,
      value: portValue
    };
  };

  const prefilledSearch: NullableSearchOptions = {
    result_type: blok.result_type || undefined,
    destination: transformDestination(blok.destination),
    line: transformCruiseLine(blok.cruise_line),
    ship: transformShip(blok.cruise_ship),
    date: transformDate(blok.date, blok.exact_date),
    passengers: null,
    duration: blok.duration || null,
    flexibility: blok.exact_date ? 0 : -1, // Set flexibility to 0 for exact dates, -1 for month selection
    regionid: blok.destination || null,
    lineid: blok.cruise_line || null,
    monthyear: blok.date ? dayjs(blok.date).format('MM_YYYY') : null,
    startingPort: transformPort(blok.start_port),
    visitingPort: transformPort(blok.visiting_port),
    formno: null,
    perpage: null,
    product: null,
    sid: null,
    sortby: null
  };

  return (
    <div {...storyblokEditable(blok)}>
      <SearchBar prefilledSearch={prefilledSearch} />
    </div>
  );
};

export default SearchBarComponent;