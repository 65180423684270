import React from 'react'
import styles from './ShipDetails.module.css'
import Image from '../../globals/image'

type Props = {
    image: string;
    title: string;
    description: string[];
}

const SliderItem = ({image, title, description}: Props) => {
  return (
    <div className={styles.sliderItem}>
      <div className={styles.imageWrapper}>
        {image ?
          <Image
          size={{ width: 1920, height: 800 }}
           asset={image}
            /> : null
        }
      </div>
      <div className={styles.sliderItemOverlay} />
      <div className={styles.sliderItemContent}>
        <h2 className={styles.sliderItemTitle}>{title}</h2>

        {description.map((desc, index) => (
          <p key={index} className={styles.sliderItemDescription}>{desc}</p>
        ))}
      </div>
    </div>
  )
}

export default SliderItem