import React from 'react'
import styles from './DestinationHightlight.module.css';
import { storyblokEditable } from "@storyblok/react/rsc"
import { DestinationHighlightsStoryblok } from '@/app/types/component-types-sb';
import PageSection from '../../globals/page_section';
import DestinationHightlight from './destination_highlight';
import HorizontalScrollView from '../../globals/carousels/custom_carousel';
import { motion } from 'framer-motion';

const DestinationHightlights = ({ blok }: { blok: DestinationHighlightsStoryblok }) => {
    return (
        <PageSection>
            <div {...storyblokEditable(blok)}>
                {blok.header && (
                    <motion.h2
                        className='section-header'
                        {...{
                            initial: { opacity: 0, y: 25 },
                            whileInView: { opacity: 1, y: 0 },
                            viewport: { once: true, margin: "-100px" },
                            transition: { duration: 0.6, ease: "easeInOut" }
                        }}
                    >
                        {blok.header}
                    </motion.h2>
                )}
                <HorizontalScrollView className={styles.horizontalScroll}>
                    {blok.slides.map((slide, index) => (
                        <motion.div
                            key={slide._uid}
                            {...{
                                initial: { opacity: 0, x: 25 },
                                whileInView: { opacity: 1, x: 0 },
                                viewport: { once: true },
                                transition: {
                                    duration: 0.6,
                                    delay: Math.min(-0.15 + (0.15 * index), 0.6),
                                    ease: "easeInOut"
                                }
                            }}
                        >
                            <DestinationHightlight blok={slide} />
                        </motion.div>
                    ))}
                </HorizontalScrollView>
            </div>
        </PageSection>
    )
}

export default DestinationHightlights