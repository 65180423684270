import React, { useEffect, useState } from "react";
import clsx from "clsx";
import TypeIcon from "../../globals/holiday/TypeIcon";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { storyblokEditable } from "@storyblok/react";
import type { StarBuyStoryblok } from "@/app/types/component-types-sb";
import { Holiday, HolidayExtra } from "@/app/types/lambda-types";
import { getStarBuyHoliday } from "@/utils/queries";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faStar } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { faCalendarDay } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import { faShip } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import styles from './StarBuy.module.css';
import Container from "../../globals/container";
import Image from '@/app/components/globals/image';
import FromPrice from "../../globals/from-price";
import Included from "./Included";
import Promotions from "../../globals/promotions";
import CustomLink from "../../globals/link";
import getIncluded from "@/utils/data-manipulation/getIncluded";
import { motion } from 'framer-motion';

const StarBuy = ({ blok }: { blok: StarBuyStoryblok }) => {
	dayjs.extend(advancedFormat);
	const [holiday, setHoliday] = useState<Holiday | undefined>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		async function fetchHoliday() {
			if (!blok.group) return;

			const result = await getStarBuyHoliday(blok.group);
			if (result.status === true) {
				setHoliday(result.holiday);
			}
			setIsLoading(false);
		}
		fetchHoliday();
	}, [])

	const header = (
		holiday ?
			<header className={styles.containerHeader}>
				<TypeIcon type={holiday.type} />

				<div className={styles.headerDetails}>
					<h3>{holiday.name}</h3>
					<div className={styles.itinerary}>
						{holiday.itinerary ? holiday.itinerary!.collection.map((port, key) => (
							<span key={key}>
								{port.name}
								{key < holiday.itinerary!.collection.length - 1 ? ' | ' : ''}
							</span>
						)) : null}
					</div>
					<div className={styles.details}>
						<span className={styles.detail}>
							<FontAwesomeIcon icon={faCalendarDay as IconProp} />
							{dayjs(holiday.start_date).format('Do MMM YY')}
						</span>
						<span className={styles.detail}>
							<FontAwesomeIcon icon={faShip as IconProp} />
							{holiday.sail_nights}-night full-board cruise aboard
							<span className={styles.ship}>{holiday.ship?.name}</span>
						</span>
					</div>
				</div>
				{holiday.from_price ?
					<FromPrice price={parseFloat(holiday.from_price).toFixed(0)} />
					: null
				}
				<div className={styles.lineLogo}>
					<Image asset={holiday!.line!.logo} />
				</div>
			</header> : null
	);

	const body = (
		holiday ?
			<>
				<footer className={styles.containerFooter}>
					<section className={styles.promotions}>
						<Promotions promotions={holiday.promotions} />
					</section>
					<footer className={styles.footer}>
						<div className={styles.includedSection}>
							<span className={styles.includedLabel}>
								INCLUDED:
							</span>
							<div className={styles.includeds}>
								{holiday.extras ? getIncluded(holiday.extras).map((extra, key) => (
									<Included key={key} holidayExtra={extra} />
								)) : null}
							</div>
						</div>
						<div className={styles.buttonContainer}>
							<CustomLink href={holiday.url} className={styles.viewButton} asButton>
								View Deal
							</CustomLink>
						</div>
					</footer>
				</footer>
			</>
			: null
	)

	return (
		<div className="page-section" {...storyblokEditable(blok)}>
			{isLoading ? (
				<Container>
					<motion.div
						{...{
							initial: { opacity: 0, y: 25 },
							whileInView: { opacity: 1, y: 0 },
							viewport: { once: true, margin: "-100px" },
							transition: { duration: 0.6, ease: "easeInOut" }
						}}
					>
						<h2 className={clsx("section-header", styles.header)}>
							<FontAwesomeIcon icon={faStar as IconProp} />
							<span>Star Buy</span>
						</h2>
					</motion.div>
					<article className={clsx(styles.container, styles.shimmer)}>
					</article>
					<p className={styles.tsAndCs}>*Prices on display are from and per person based on 2 sharing the lowest cabin grade unless otherwise stated. Gratuities are not included in any of these offers unless otherwise stated. Booking fee & Baggage charge may apply. No Booking fee is payable when you book online. Cruise Nation and Cruise Line Terms & Conditions apply. E&OE.</p>
				</Container>
			) : (
				<Container>
					<motion.div
						{...{
							initial: { opacity: 0, y: 25 },
							whileInView: { opacity: 1, y: 0 },
							viewport: { once: true, margin: "-100px" },
							transition: { duration: 0.6, ease: "easeInOut" }
						}}
					>
						<h2 className={clsx("section-header", styles.header)}>
							<FontAwesomeIcon icon={faStar as IconProp} />
							<span>Star Buy</span>
						</h2>
					</motion.div>

					<motion.div
						{...{
							initial: { opacity: 0 },
							whileInView: { opacity: 1 },
							viewport: { once: true, margin: "-100px" },
							transition: { duration: 0.6, ease: "easeInOut" }
						}}
					>
						<article className={styles.container}>
							{holiday ? <Image asset={holiday.port_guides?.collection[0]?.image_path || ''} /> : null}
							<div className={styles.gradientOverlay}></div>
							<div className={styles.darkGradientOverlay}></div>
							{header}
							{body}
						</article>
					</motion.div>
					<p className={styles.tsAndCs}>*Prices on display are from and per person based on 2 sharing the lowest cabin grade unless otherwise stated. Gratuities are not included in any of these offers unless otherwise stated. Booking fee & Baggage charge may apply. No Booking fee is payable when you book online. Cruise Nation and Cruise Line Terms & Conditions apply. E&OE.</p>
				</Container>
			)}
		</div>
	);
}

export default StarBuy;