import styles from "./Header.module.css"
import Image from '../../globals/image';
import type { HeaderStoryblok } from '@/app/types/component-types-sb';
import React from 'react'

type Props = {
  blok: HeaderStoryblok
}

const ShortHeader = ({ blok }: Props) => {
  return (
    <div className={styles.main}>
      <div className={styles.imageWrapperShort}>
        {blok.background_image ?
          <Image
            lazyLoad={false}
            asset={blok.background_image}
            size={{
              width: 1920,
              height: 100
            }}
          /> : null
        }
      </div>
      <div className={styles.overlayShort} />
    </div>
  )
}

export default ShortHeader