import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/globals/cookie-manager/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/globals/navigation/NavigationWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/globals/signup-dialog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/storyblok-globals/navigation_story/Dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/storyblok-globals/navigation_story/MobileLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/StoryblokProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/styles/colors.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/storyblok-globals/navigation_story/Navigation.module.css");
