import React from 'react';
import type { FleetStoryblok, ShipTileStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from '@storyblok/react';
import styles from './FleetGrid.module.css';
import ShipTile from './ShipTile';
import clsx from 'clsx';
import { generate1DArray } from '@/utils/actions';
import { motion, useInView } from 'framer-motion';

const Fleet = ({ blok }: { blok: FleetStoryblok }) => {
  const containerRef = React.useRef(null);
  const isInView = useInView(containerRef, { once: true, margin: "-100px" });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0 // Delay after container becomes visible
      }
    }
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.75,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className='page-section' {...storyblokEditable(blok)}>
      <div className='container container--expanded'>
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <h2 className='section-header'>{blok.title}</h2>
        </motion.div>
        {!blok.ships ? (
          <div className={styles["grid-container"]}>
            {generate1DArray(6, 5).map((row, rowIndex) => (
              <div key={rowIndex} className={clsx(styles["grid-item"], styles["shimmer"])}></div>
            ))}
          </div>
        ) : (
          <motion.div
            ref={containerRef}
            className={styles.ships}
            variants={container}
            initial="hidden"
            animate={isInView ? "show" : "hidden"}
          >
            {blok.ships.map((ship: ShipTileStoryblok) => (
              <motion.div key={ship._uid} variants={item}>
                <ShipTile ship={ship} />
              </motion.div>
            ))}
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default Fleet;