"use client"

import React, { useState, useEffect } from 'react';
import styles from './CookieManger.module.css'
import Button from '../button';
import CustomLink from '../link';
import { getCookie, setCookie } from '@/utils/cookies';

const CookieBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const consentCookie = getCookie('cruiseNationCookieConsent');
    const necessaryCookie = getCookie('cruiseNationNecessaryCookies');
    if (!consentCookie && !necessaryCookie) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = (): void => {
    setCookie('cruiseNationCookieConsent', 'all');
    setCookie('cruiseNationAnalyticsConsent', 'yes');
    setCookie('cruiseNationMarketingConsent', 'yes');
    setShowBanner(false);
  };

  const handleAcceptNecessary = (): void => {
    setCookie('cruiseNationNecessaryCookies', 'necessary');
    setCookie('cruiseNationMarketingConsent', 'no');
    setShowBanner(false);
  };

  const handleDecline = (): void => {
    const expires = new Date();
    expires.setHours(expires.getHours() + 24);
    setCookie('cruiseNationCookieConsent', 'declined', { expires });
    setCookie('cruiseNationAnalyticsConsent', 'no');
    setCookie('cruiseNationMarketingConsent', 'no');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className={styles['cookie-banner']}>
      <div className={styles['cookie-banner__container']}>
        <div className={styles['cookie-banner__content']}>
          <span className={styles['cookie-banner__icon']} role="img" aria-label="cookie">
            🍪
          </span>
          <p className={styles['cookie-banner__text']}>
            We use cookies and other technologies to provide you with an award winning experience.{' '}
            <CustomLink
              href="/privacy"
              className={styles['cookie-banner__link']}
            >
              Read more about how we are using them in our privacy policy.
            </CustomLink>
          </p>
        </div>
        <div className={styles['cookie-banner__buttons']}>
          <Button
            onClick={handleAcceptAll}
            variant='primary'
          >
            ACCEPT ALL
          </Button>
          <Button
            onClick={handleAcceptNecessary}
            variant='outline'
          >
            ACCEPT NECESSARY
          </Button>
          <Button
            onClick={handleDecline}
            variant='transparent'
          >
            NO THANKS
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;