'use client' // Error boundaries must be Client Components

import { useEffect } from 'react'
import './styles/colors.css';
import './globals.css';
import Button from './components/globals/button';

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    useEffect(() => {
        // Log the error to an error reporting service
        //console.error(error)
    }, [error])

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="container">
                <h1>A little turbulence at sea!</h1>
                <p>Looks like we&apos;ve hit some choppy waters, but don&apos;t worry—we&apos;re navigating back to calm seas. Try refreshing the page or come back shortly.</p>
                <h2>Need a hand?</h2>
                <ul style={{ listStyle: 'inside' }}>
                    <li><a href="/">Return to the homepage</a></li>
                    <li><a href="/knowledge-base">Contact us for assistance</a></li>
                </ul>
                <Button
                    style={{ marginTop: '1rem' }}
                    onClick={
                        // Attempt to recover by trying to re-render the segment
                        () => reset()
                    }
                >
                    Try again
                </Button>
            </div>
        </div>
    )
}