import { WhatsIncludedItemStoryblok } from '@/app/types/component-types-sb'
import { WhatsIncludedType } from '@/app/types/lambda-types';
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from './WhatsIncluded.module.css';
import React from 'react'
import Image from '../../globals/image';

const WhatsIncludedItem = ({ blok }: { blok: WhatsIncludedItemStoryblok | WhatsIncludedType }) => {
  if (blok.description) {
    return (
      <div className={styles.whatsIncludedItem}>
        <div className={styles.imageBlock}>
        <Image
          asset={blok.icon || ""}
          alt='Icon'
          className={styles.icon}
          size={{ width: 50, height: 50 }}
        />
      </div>
        <p className={styles.description}>{blok.description}</p>
      </div>
    )
  }


  return (
    <div className={styles.whatsIncludedItem} {...storyblokEditable(blok as WhatsIncludedItemStoryblok)}>
      <div className={styles.imageBlock}>
        <Image
          asset={blok.icon || ""}
          alt='Icon'
          className={styles.icon}
          size={{ width: 50, height: 50 }}
        />
      </div>
      <p className={styles.description}>{(blok as WhatsIncludedItemStoryblok).content}</p>
    </div>
  )
}

export default WhatsIncludedItem