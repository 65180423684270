import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/globals/feefo_footer/FeefoFooterClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/storyblok-globals/footer/first_footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/storyblok-globals/footer/Footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/storyblok-globals/footer/second_footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/storyblok-globals/footer/third_footer/index.tsx");
