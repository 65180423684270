import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  EmblaCarouselType,
  EmblaEventType,
  EmblaOptionsType
} from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import styles from "./Slider.module.css";
import { useEmblaPrevNextButtons } from '@/utils/hooks/useEmblaCarouselButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight } from '@awesome.me/kit-d4c82d9167/icons/classic/solid';
import Container from '../../globals/container';
import Image from '../../globals/image';
import { SliderItemStoryblok } from '@/app/types/component-types-sb';

const TWEEN_FACTOR_BASE = 0.52
const MIN_SCALE = 1
const MAX_TRANSLATE_Y = 25
const FOCUS_THRESHOLD = 0.9

const numberWithinRange = (number: number, min: number, max: number): number =>
  Math.min(Math.max(number, min), max)

type PropType = {
  children: React.ReactNode
  options?: EmblaOptionsType
  blok?: SliderItemStoryblok[]
}

const Carousel: React.FC<PropType> = ({ children, options, blok }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const tweenFactor = useRef(0)
  const tweenNodes = useRef<HTMLElement[]>([])
  const [focusedIndices, setFocusedIndices] = useState<Set<number>>(new Set())
  const [animatedIndices, setAnimatedIndices] = useState<Set<number>>(new Set())

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = useEmblaPrevNextButtons(emblaApi)

  const setTweenNodes = useCallback((emblaApi: EmblaCarouselType): void => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector(`.${styles["embla__slide__number"]}`) as HTMLElement
    })
  }, [])

  const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
  }, [])

  const tweenScaleAndTranslate = useCallback(
    (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
      const engine = emblaApi.internalEngine()
      const scrollProgress = emblaApi.scrollProgress()
      const slidesInView = emblaApi.slidesInView()
      const isScrollEvent = eventName === 'scroll'
      const newFocusedIndices = new Set<number>()

      emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        let diffToTarget = scrollSnap - scrollProgress
        const slidesInSnap = engine.slideRegistry[snapIndex]

        slidesInSnap.forEach((slideIndex) => {
          if (isScrollEvent && !slidesInView.includes(slideIndex)) return

          if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach((loopItem) => {
              const target = loopItem.target()

              if (slideIndex === loopItem.index && target !== 0) {
                const sign = Math.sign(target)

                if (sign === -1) {
                  diffToTarget = scrollSnap - (1 + scrollProgress)
                }
                if (sign === 1) {
                  diffToTarget = scrollSnap + (1 - scrollProgress)
                }
              }
            })
          }

          const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current)
          const scale = numberWithinRange(tweenValue, MIN_SCALE, 1)
          const translateY = numberWithinRange((1 - tweenValue) * MAX_TRANSLATE_Y, 0, MAX_TRANSLATE_Y)
          const tweenNode = tweenNodes.current[slideIndex]

          if (tweenValue > FOCUS_THRESHOLD) {
            newFocusedIndices.add(slideIndex)
          }

          if (tweenNode) {
            tweenNode.style.transform = `scale(${scale}) translateY(${translateY}%)`
            tweenNode.style.opacity = `${tweenValue}`
          }
        })
      })

      setFocusedIndices(newFocusedIndices)
    },
    []
  )

  useEffect(() => {
    if (!emblaApi) return

    setTweenNodes(emblaApi)
    setTweenFactor(emblaApi)
    tweenScaleAndTranslate(emblaApi)

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenScaleAndTranslate)
      .on('scroll', tweenScaleAndTranslate)
      .on('slideFocus', tweenScaleAndTranslate)
  }, [emblaApi, setTweenNodes, setTweenFactor, tweenScaleAndTranslate])

  // Effect to handle animation timing
  useEffect(() => {
    const newAnimatedIndices = new Set(animatedIndices)
    focusedIndices.forEach(index => {
      if (!animatedIndices.has(index)) {
        setTimeout(() => {
          newAnimatedIndices.add(index)
          setAnimatedIndices(new Set(newAnimatedIndices))
        }, 100) // Slight delay to ensure slide is in view
      }
    })
    // Remove indices that are no longer focused
    animatedIndices.forEach(index => {
      if (!focusedIndices.has(index)) {
        newAnimatedIndices.delete(index)
      }
    })
    setAnimatedIndices(newAnimatedIndices)
  }, [focusedIndices])

  const slideStyles = {
    subImages: {
      position: 'absolute',
      bottom: '-50px',
      right: '300px',
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      padding: '20px',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.6s ease-out',
      opacity: 0,
      transform: 'translateY(50px)',
    } as const,
    subImage: {
      width: '250px',
      height: '150px',
      position: 'relative',
    } as const,
    subImagesVisible: {
      opacity: 1,
      transform: 'translateY(0)',
    } as const
  }

  const childrenArray = React.Children.toArray(children);

  return (
    <div className={styles["embla"]}>
      <div className={styles["embla__viewport"]} ref={emblaRef}>
        <div className={styles["embla__container"]}>
          {childrenArray.map((item, index) => (
            <div className={styles["embla__slide"]} key={index}>
              <div className={styles["embla__slide__number"]}>
                {focusedIndices.has(index) ? (
                  <Container>
                    {item}
                  </Container>
                ) : (
                  <Container style={{ padding: 0 }}>
                    {item}
                  </Container>
                )}
              </div>
            </div>
          ))}
        </div>
        {blok && childrenArray.map((item, index) => (
          <React.Fragment key={index}>
            {focusedIndices.has(index) && (
              <>
                {((blok[index]?.sub_image1 && blok[index]?.sub_image1.filename) && blok[index]?.sub_image2 && blok[index]?.sub_image2.filename) && (
                  <div
                    style={{
                      ...slideStyles.subImages,
                      ...(animatedIndices.has(index) ? slideStyles.subImagesVisible : {})
                    }}
                  >
                    <div style={slideStyles.subImage}>
                      <Image
                        size={{ width: 250, height: 150 }}
                        asset={blok[index].sub_image1}
                      />
                    </div>
                    <div style={slideStyles.subImage}>
                      <Image
                        size={{ width: 250, height: 150 }}
                        asset={blok[index].sub_image2}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>

      <button className={styles["embla__prev"]} onClick={onPrevButtonClick} disabled={prevBtnDisabled}>
        <FontAwesomeIcon icon={faChevronLeft as IconProp} size='5x' color='white' />
      </button>
      <button className={styles["embla__next"]} onClick={onNextButtonClick} disabled={nextBtnDisabled}>
        <FontAwesomeIcon icon={faChevronRight as IconProp} size='5x' color='white' />
      </button>
    </div>
  )
}

export default Carousel