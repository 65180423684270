import { ContentSplit2ImagesStoryblok } from '@/app/types/component-types-sb'
import ContentSplitTwoImageTextImage from './ContentSplitTwoImageTextImage';
import styles from './ContentSplitTwoImages.module.css'

import React from 'react'
import clsx from 'clsx';
import { storyblokEditable } from '@storyblok/react/rsc';

const ContentSplit2Images = ({ blok }: { blok: ContentSplit2ImagesStoryblok }) => {
  return (
    <div className={clsx('page-section', styles.outterContainer)}  {...storyblokEditable(blok)}>
      <ContentSplitTwoImageTextImage
        flip={blok.flip}
        title={blok.title_1}
        textContent={blok.text_content_1}
        image={blok.image_1}
        imageTitle={blok.image_1_title}
        imageDecription={blok.image_1_description}
        button={blok.button_1}
        wideImage={blok.wide_image_1}
        animation={blok.flip ? "fadeInLeft" : "fadeInRight"}
      />

      <ContentSplitTwoImageTextImage
        flip={!blok.flip}
        title={blok.title_2}
        textContent={blok.text_content_2}
        image={blok.image_2}
        imageTitle={blok.image_2_title}
        imageDecription={blok.image_2_description}
        button={blok.button_2}
        wideImage={blok.wide_image_2}
        animation={blok.flip ? "wipeInReverse" : "wipeIn"}
      />

    </div>
  )
}

export default ContentSplit2Images