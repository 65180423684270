import React from 'react';
import styles from './SearchBar.module.css';

type CruiseLine = {
  label: string;
  value: string;
  selected?: boolean;
};

type Ship = {
  label: string;
  value: string;
  group: number;
  selected?: boolean;
};

interface LineShipDropdownProps {
  cruiseLines: CruiseLine[];
  ships: Ship[];
  selectedLine: CruiseLine;
  selectedShip: Ship | null;
  onLineChange: (line: CruiseLine) => void;
  onShipChange: (ship: Ship) => void;
  isLoading: boolean;
  error: string | null;
}

const LineShipDropdown: React.FC<LineShipDropdownProps> = ({
  cruiseLines,
  ships,
  selectedLine,
  selectedShip,
  onLineChange,
  onShipChange,
  isLoading,
  error
}) => {
  // Filter ships based on selected cruise line
  const filteredShips = ships.filter(
    ship => selectedLine.value === '' || ship.group.toString() === selectedLine.value
  );

  // Create "Any Ship" option
  const anyShipOption: Ship = {
    label: 'Any Ship',
    value: '',
    group: -1,
    selected: false
  };

  const handleShipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      onShipChange(anyShipOption);
    } else {
      const selectedShip = ships.find(s => s.value === e.target.value);
      if (selectedShip) {
        onShipChange(selectedShip);
      }
    }
  };

  if (isLoading) {
    return (
      <div className={styles.dropdownLinesShips}>
        <div className={styles.loadingMessage}>Loading ships...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.dropdownLinesShips}>
        <div className={styles.errorMessage}>Error loading ships. Please try again later.</div>
      </div>
    );
  }

  return (
    <div className={styles.dropdownLinesShips}>
      <div className={styles.dropdownDropdownListOptions}>
        {cruiseLines.map((line) => (
          <button
            key={line.value}
            className={`${styles.listOption} ${selectedLine.value === line.value ? styles.listOptionSelected : ''
              }`}
            onClick={() => onLineChange(line)}
          >
            {line.label}
          </button>
        ))}
      </div>
      <div className={styles.dropdownLinesShipsShips}>
        <div></div>
        <div className={styles.advancedSearchShips}>
          Ship
          <select
            value={selectedShip?.value ?? ''}
            onChange={handleShipChange}
          >
            <option key="any-ship" value="">{anyShipOption.label}</option>
            {filteredShips.map((ship) => (
              <option key={ship.value} value={ship.value}>
                {ship.label}
              </option>
            ))}
          </select>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default LineShipDropdown;