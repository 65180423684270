const visitPorts = [
  { label: "Any Port", value: null },
  { value: "Aabenraa, Denmark", label: "Aabenraa, Denmark" },
  { value: "Aalborg", label: "Aalborg" },
  { value: "Aapilattoq, Greenland", label: "Aapilattoq, Greenland" },
  { value: "Aarhus", label: "Aarhus" },
  { value: "Aberdeen", label: "Aberdeen" },
  { value: "Abidjan", label: "Abidjan" },
  { value: "Abraao", label: "Abraao" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Aburatsu (Japan)", label: "Aburatsu (Japan)" },
  { value: "Acajutla", label: "Acajutla" },
  { value: "Acapulco", label: "Acapulco" },
  { value: "Ad Dakhla", label: "Ad Dakhla" },
  { value: "Adamstown, Pitcairn Islands", label: "Adamstown, Pitcairn Islands" },
  { value: "Adelaide", label: "Adelaide" },
  { value: "Agadir", label: "Agadir" },
  { value: "Agats, Asmat", label: "Agats, Asmat" },
  { value: "Aghios Nikolaos, Crete", label: "Aghios Nikolaos, Crete" },
  { value: "Ainsworth Bay, Australia", label: "Ainsworth Bay, Australia" },
  { value: "Airlie Beach", label: "Airlie Beach" },
  { value: "Aitutaki", label: "Aitutaki" },
  { value: "Ajaccio", label: "Ajaccio" },
  { value: "Akaroa", label: "Akaroa" },
  { value: "Akita", label: "Akita" },
  { value: "Akpatok", label: "Akpatok" },
  { value: "Akureyri", label: "Akureyri" },
  { value: "Al Manama", label: "Al Manama" },
  { value: "Albany, Australia", label: "Albany, Australia" },
  { value: "Alcudia, Mallorca", label: "Alcudia, Mallorca" },
  { value: "Alert Bay", label: "Alert Bay" },
  { value: "Alesund", label: "Alesund" },
  { value: "Alexander Selkirk Island", label: "Alexander Selkirk Island" },
  { value: "Alexandria", label: "Alexandria" },
  { value: "Alghero", label: "Alghero" },
  { value: "Alicante", label: "Alicante" },
  { value: "Almeria", label: "Almeria" },
  { value: "Alofi", label: "Alofi" },
  { value: "Alotau", label: "Alotau" },
  { value: "Alta do Chao, Portugal", label: "Alta do Chao, Portugal" },
  { value: "Alta", label: "Alta" },
  { value: "Alter Do Chao, Brazil", label: "Alter Do Chao, Brazil" },
  { value: "Amalfi", label: "Amalfi" },
  { value: "Amalia Glacier", label: "Amalia Glacier" },
  { value: "Amami Oshima (Naze)", label: "Amami Oshima (Naze)" },
  { value: "Amber Cove, Dominican Republic", label: "Amber Cove, Dominican Republic" },
  { value: "Ambryn Island", label: "Ambryn Island" },
  { value: "Amorgos", label: "Amorgos" },
  { value: "Amsterdam", label: "Amsterdam" },
  { value: "Anadyr", label: "Anadyr" },
  { value: "Anak Krakatoa, Indonesia", label: "Anak Krakatoa, Indonesia" },
  { value: "Anano Island, Indonesia", label: "Anano Island, Indonesia" },
  { value: "Anavilhanas, Archipelago", label: "Anavilhanas, Archipelago" },
  { value: "Anchorage", label: "Anchorage" },
  { value: "Ancora", label: "Ancora" },
  { value: "Andalsnes", label: "Andalsnes" },
  { value: "Anegada, British Virgin Islands", label: "Anegada, British Virgin Islands" },
  { value: "Angra Dos Reis", label: "Angra Dos Reis" },
  { value: "Anguilla,BWI", label: "Anguilla,BWI" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antibes", label: "Antibes" },
  { value: "Antigua, Leeward Islands", label: "Antigua, Leeward Islands" },
  { value: "Antilla, Cuba", label: "Antilla, Cuba" },
  { value: "Antofagasta", label: "Antofagasta" },
  { value: "Antwerp", label: "Antwerp" },
  { value: "Aomori", label: "Aomori" },
  { value: "Apia", label: "Apia" },
  { value: "Apra", label: "Apra" },
  { value: "Aqaba", label: "Aqaba" },
  { value: "Archangel, French Polynesia", label: "Archangel, French Polynesia" },
  { value: "Arendal", label: "Arendal" },
  { value: "Argostoli", label: "Argostoli" },
  { value: "Arhus", label: "Arhus" },
  { value: "Arica", label: "Arica" },
  { value: "Arles", label: "Arles" },
  { value: "Armacao dos Buzio", label: "Armacao dos Buzio" },
  { value: "Arnhem, Netherlands", label: "Arnhem, Netherlands" },
  { value: "Arrecife, Lanzarote, Canary Islands", label: "Arrecife, Lanzarote, Canary Islands" },
  { value: "Aruba", label: "Aruba" },
  { value: "Ashdod", label: "Ashdod" },
  { value: "Astoria,Oregon", label: "Astoria,Oregon" },
  { value: "Athens (Piraeus)", label: "Athens (Piraeus)" },
  { value: "Atlasova", label: "Atlasova" },
  { value: "Attu Island, Alaska", label: "Attu Island, Alaska" },
  { value: "Auckland", label: "Auckland" },
  { value: "Aurland", label: "Aurland" },
  { value: "Avera Bay, Rurutu, Austral Islands", label: "Avera Bay, Rurutu, Austral Islands" },
  { value: "Avignon", label: "Avignon" },
  { value: "Aviles", label: "Aviles" },
  { value: "Bahama Islands", label: "Bahama Islands" },
  { value: "Bahia d' Opunoha, Moorea", label: "Bahia d' Opunoha, Moorea" },
  { value: "Bahia Darwin, Genovesa", label: "Bahia Darwin, Genovesa" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Baie-Commeau, Quebec", label: "Baie-Commeau, Quebec" },
  { value: "Balboa", label: "Balboa" },
  { value: "Bali", label: "Bali" },
  { value: "Baltimore", label: "Baltimore" },
  { value: "Baltra, Galapagos Islands", label: "Baltra, Galapagos Islands" },
  { value: "Bamberg", label: "Bamberg" },
  { value: "Banana Island", label: "Banana Island" },
  { value: "Banda Aceh, Indonesia", label: "Banda Aceh, Indonesia" },
  { value: "Bandol", label: "Bandol" },
  { value: "Bangkok", label: "Bangkok" },
  { value: "Banjul", label: "Banjul" },
  { value: "Bantry, County Cork", label: "Bantry, County Cork" },
  { value: "Baoshan (Shanghai)", label: "Baoshan (Shanghai)" },
  { value: "Bar Harbor", label: "Bar Harbor" },
  { value: "Bar", label: "Bar" },
  { value: "Barca d’Alva", label: "Barca d’Alva" },
  { value: "Barcelona", label: "Barcelona" },
  { value: "Bari", label: "Bari" },
  { value: "Bartalome", label: "Bartalome" },
  { value: "Bartholome Islands, Galapagos Islands", label: "Bartholome Islands, Galapagos Islands" },
  { value: "Bartolome, Galapagos", label: "Bartolome, Galapagos" },
  { value: "Basel", label: "Basel" },
  { value: "Basseterre", label: "Basseterre" },
  { value: "Bastia, Corsica", label: "Bastia, Corsica" },
  { value: "Bau-Bau, Indonesia", label: "Bau-Bau, Indonesia" },
  { value: "Bay of Islands", label: "Bay of Islands" },
  { value: "Beijing", label: "Beijing" },
  { value: "Belem", label: "Belem" },
  { value: "Belfast", label: "Belfast" },
  { value: "Belgrade", label: "Belgrade" },
  { value: "Belitung Islands, Indonesia", label: "Belitung Islands, Indonesia" },
  { value: "Belize", label: "Belize" },
  { value: "Belle-Ile-en–Mer", label: "Belle-Ile-en–Mer" },
  { value: "Benoa/Bali", label: "Benoa/Bali" },
  { value: "Beppu", label: "Beppu" },
  { value: "Bequia", label: "Bequia" },
  { value: "Bergen", label: "Bergen" },
  { value: "Berlin", label: "Berlin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bernkastel", label: "Bernkastel" },
  { value: "Biarritz", label: "Biarritz" },
  { value: "Bilbao", label: "Bilbao" },
  { value: "Bimini Islands", label: "Bimini Islands" },
  { value: "Bintulu", label: "Bintulu" },
  { value: "Black Turtle Cove, Santa Cruz Island", label: "Black Turtle Cove, Santa Cruz Island" },
  { value: "Boca De Valeira", label: "Boca De Valeira" },
  { value: "Bocas del Toro", label: "Bocas del Toro" },
  { value: "Bodo", label: "Bodo" },
  { value: "Bom Bom Island", label: "Bom Bom Island" },
  { value: "Bonaire", label: "Bonaire" },
  { value: "Bonaventure Island", label: "Bonaventure Island" },
  { value: "Bonifacio", label: "Bonifacio" },
  { value: "Bonn", label: "Bonn" },
  { value: "Bora Bora", label: "Bora Bora" },
  { value: "Boracay", label: "Boracay" },
  { value: "Bordeaux", label: "Bordeaux" },
  { value: "Bornholm", label: "Bornholm" },
  { value: "Boston, USA", label: "Boston, USA" },
  { value: "Bozcaada", label: "Bozcaada" },
  { value: "Bratislava", label: "Bratislava" },
  { value: "Braubach", label: "Braubach" },
  { value: "Brazil", label: "Brazil" },
  { value: "Breisach", label: "Breisach" },
  { value: "Bremen", label: "Bremen" },
  { value: "Bremerhaven", label: "Bremerhaven" },
  { value: "Brest", label: "Brest" },
  { value: "Bridgetown", label: "Bridgetown" },
  { value: "Brindisi", label: "Brindisi" },
  { value: "Brisbane", label: "Brisbane" },
  { value: "Bristol", label: "Bristol" },
  { value: "Bronnoysund", label: "Bronnoysund" },
  { value: "Broome", label: "Broome" },
  { value: "Brugge", label: "Brugge" },
  { value: "Brussels", label: "Brussels" },
  { value: "Buccaneer Archipelago", label: "Buccaneer Archipelago" },
  { value: "Budapest", label: "Budapest" },
  { value: "Buenos Aires", label: "Buenos Aires" },
  { value: "Burnie, Tasmania", label: "Burnie, Tasmania" },
  { value: "Busan, Republic of Korea (South Korea)", label: "Busan, Republic of Korea (South Korea)" },
  { value: "Busselton", label: "Busselton" },
  { value: "Buzios", label: "Buzios" },
  { value: "Cabo Frio", label: "Cabo Frio" },
  { value: "Cabo San Lucas", label: "Cabo San Lucas" },
  { value: "Cadiz", label: "Cadiz" },
  { value: "Cagliari", label: "Cagliari" },
  { value: "Cai Lan", label: "Cai Lan" },
  { value: "Cairns", label: "Cairns" },
  { value: "Caleta Tagus, Isabela", label: "Caleta Tagus, Isabela" },
  { value: "Callao, Peru", label: "Callao, Peru" },
  { value: "Calvi", label: "Calvi" },
  { value: "Camarones", label: "Camarones" },
  { value: "Camboriu, Brazil", label: "Camboriu, Brazil" },
  { value: "Cancun", label: "Cancun" },
  { value: "Cannakkale", label: "Cannakkale" },
  { value: "Cannes", label: "Cannes" },
  { value: "Cap-aux-Meules, Quebec", label: "Cap-aux-Meules, Quebec" },
  { value: "Cape Dezhnev", label: "Cape Dezhnev" },
  { value: "Cape Horn, Chile", label: "Cape Horn, Chile" },
  { value: "Cape Liberty, New Jersey", label: "Cape Liberty, New Jersey" },
  { value: "Cape Navarin", label: "Cape Navarin" },
  { value: "Cape Town", label: "Cape Town" },
  { value: "Cape York", label: "Cape York" },
  { value: "Capri", label: "Capri" },
  { value: "Cardiff, Wales, UK", label: "Cardiff, Wales, UK" },
  { value: "Cartagena, Colombia", label: "Cartagena, Colombia" },
  { value: "Cartagena, Spain", label: "Cartagena, Spain" },
  { value: "Casablanca", label: "Casablanca" },
  { value: "Cassis", label: "Cassis" },
  { value: "Castaway Cay", label: "Castaway Cay" },
  { value: "Castellon de la Plana", label: "Castellon de la Plana" },
  { value: "Castries", label: "Castries" },
  { value: "Castro, Chiloe Island", label: "Castro, Chiloe Island" },
  { value: "Catalina Island", label: "Catalina Island" },
  { value: "Catalina Islands, California", label: "Catalina Islands, California" },
  { value: "Catania, Sicily", label: "Catania, Sicily" },
  { value: "Caudebec En Caux", label: "Caudebec En Caux" },
  { value: "Cavalaire-sur-Mer, France", label: "Cavalaire-sur-Mer, France" },
  { value: "Cayman Brac", label: "Cayman Brac" },
  { value: "Celukan Bawang, Bali", label: "Celukan Bawang, Bali" },
  { value: "Cephalonia", label: "Cephalonia" },
  { value: "Cerro Dragon, Santa Cruz Island", label: "Cerro Dragon, Santa Cruz Island" },
  { value: "Ceuta", label: "Ceuta" },
  { value: "Chalon-Sur-Saone", label: "Chalon-Sur-Saone" },
  { value: "Champagne Bay, Vanuatu", label: "Champagne Bay, Vanuatu" },
  { value: "Chan May", label: "Chan May" },
  { value: "Chania, Crete", label: "Chania, Crete" },
  { value: "Charleston", label: "Charleston" },
  { value: "Charlestown, Nevis", label: "Charlestown, Nevis" },
  { value: "Charlotte Amalie", label: "Charlotte Amalie" },
  { value: "Charlottetown", label: "Charlottetown" },
  { value: "Chennai", label: "Chennai" },
  { value: "Cherbourg", label: "Cherbourg" },
  { value: "Chichijima", label: "Chichijima" },
  { value: "Chignik, Alaska", label: "Chignik, Alaska" },
  { value: "Chiswell Islands, Alaska", label: "Chiswell Islands, Alaska" },
  { value: "Christchurch", label: "Christchurch" },
  { value: "Cienfuegos, Cuba", label: "Cienfuegos, Cuba" },
  { value: "Cinque Terre", label: "Cinque Terre" },
  { value: "Cliffs of Moher", label: "Cliffs of Moher" },
  { value: "Cobh", label: "Cobh" },
  { value: "Coburg Island", label: "Coburg Island" },
  { value: "Cochem", label: "Cochem" },
  { value: "Cochin", label: "Cochin" },
  { value: "CocoCay", label: "CocoCay" },
  { value: "Cocos Island, Costa Rica", label: "Cocos Island, Costa Rica" },
  { value: "College Fjord", label: "College Fjord" },
  { value: "Cologne", label: "Cologne" },
  { value: "Colombo, Sri Lanka", label: "Colombo, Sri Lanka" },
  { value: "Colon, Panama", label: "Colon, Panama" },
  { value: "Conflict Islands, PNG", label: "Conflict Islands, PNG" },
  { value: "Cooktown", label: "Cooktown" },
  { value: "Copacabana Beach", label: "Copacabana Beach" },
  { value: "Copacabana", label: "Copacabana" },
  { value: "Copenhagen", label: "Copenhagen" },
  { value: "Coquimbo", label: "Coquimbo" },
  { value: "Corfu", label: "Corfu" },
  { value: "Corinth (for Ancient Corinth", label: "Corinth (for Ancient Corinth" },
  { value: "Corinto", label: "Corinto" },
  { value: "Cork, Ireland", label: "Cork, Ireland" },
  { value: "Cormorant Point", label: "Cormorant Point" },
  { value: "Corner Brook, Newfoundland", label: "Corner Brook, Newfoundland" },
  { value: "Cornwall", label: "Cornwall" },
  { value: "Coron, Philippines", label: "Coron, Philippines" },
  { value: "Corsica", label: "Corsica" },
  { value: "Costa Maya", label: "Costa Maya" },
  { value: "Cote d’Azur", label: "Cote d’Azur" },
  { value: "Cotonou", label: "Cotonou" },
  { value: "Cowes, Isle of Wight", label: "Cowes, Isle of Wight" },
  { value: "Cozumel", label: "Cozumel" },
  { value: "Crete", label: "Crete" },
  { value: "Cristobal, Panama", label: "Cristobal, Panama" },
  { value: "Crotone", label: "Crotone" },
  { value: "Curacao", label: "Curacao" },
  { value: "Da Nang", label: "Da Nang" },
  { value: "Dakar", label: "Dakar" },
  { value: "Dalian", label: "Dalian" },
  { value: "Daphne Island, Galapagos", label: "Daphne Island, Galapagos" },
  { value: "Dar Es Salaam", label: "Dar Es Salaam" },
  { value: "Darien Jungle, Panama", label: "Darien Jungle, Panama" },
  { value: "Dartmouth", label: "Dartmouth" },
  { value: "Darwin, Australia", label: "Darwin, Australia" },
  { value: "Deventer", label: "Deventer" },
  { value: "Devils Island, French Guiana", label: "Devils Island, French Guiana" },
  { value: "Devon Island", label: "Devon Island" },
  { value: "Diego Suarez", label: "Diego Suarez" },
  { value: "Dili, Timor-Leste", label: "Dili, Timor-Leste" },
  { value: "Djupivogur", label: "Djupivogur" },
  { value: "Dobu Island", label: "Dobu Island" },
  { value: "Doha", label: "Doha" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dordrecht", label: "Dordrecht" },
  { value: "Doubtful Sound", label: "Doubtful Sound" },
  { value: "Douglas", label: "Douglas" },
  { value: "Dover", label: "Dover" },
  { value: "Dragon Hill (Santa Cruz)", label: "Dragon Hill (Santa Cruz)" },
  { value: "Dravuni, Fiji Islands", label: "Dravuni, Fiji Islands" },
  { value: "Dubai", label: "Dubai" },
  { value: "Dublin", label: "Dublin" },
  { value: "Dubrovnik", label: "Dubrovnik" },
  { value: "Ducie Island, Pitcairn Islands", label: "Ducie Island, Pitcairn Islands" },
  { value: "Dundee, Scotland", label: "Dundee, Scotland" },
  { value: "Dunedin, New Zealand", label: "Dunedin, New Zealand" },
  { value: "Dunkirk (for Lille)", label: "Dunkirk (for Lille)" },
  { value: "Dunmore East", label: "Dunmore East" },
  { value: "Durban", label: "Durban" },
  { value: "Durnstein", label: "Durnstein" },
  { value: "Durres", label: "Durres" },
  { value: "Dusky Sound", label: "Dusky Sound" },
  { value: "Dusseldorf", label: "Dusseldorf" },
  { value: "Dutch Harbor, Alaska", label: "Dutch Harbor, Alaska" },
  { value: "Easo", label: "Easo" },
  { value: "East London", label: "East London" },
  { value: "Easter Island", label: "Easter Island" },
  { value: "Eden", label: "Eden" },
  { value: "Edinburgh", label: "Edinburgh" },
  { value: "Eidfjord", label: "Eidfjord" },
  { value: "Eilat", label: "Eilat" },
  { value: "El Barranco, Genovesa Island", label: "El Barranco, Genovesa Island" },
  { value: "El Ferrol", label: "El Ferrol" },
  { value: "Elba", label: "Elba" },
  { value: "Elephant Island", label: "Elephant Island" },
  { value: "Elfin Cove, Alaska", label: "Elfin Cove, Alaska" },
  { value: "Elizabeth Bay, Isabela", label: "Elizabeth Bay, Isabela" },
  { value: "Ende, Flores", label: "Ende, Flores" },
  { value: "Endicott Arm", label: "Endicott Arm" },
  { value: "Ensenada", label: "Ensenada" },
  { value: "Ephesus", label: "Ephesus" },
  { value: "Ermoupoli", label: "Ermoupoli" },
  { value: "Esbjerg", label: "Esbjerg" },
  { value: "Eskifjordur", label: "Eskifjordur" },
  { value: "Esmeraldas", label: "Esmeraldas" },
  { value: "Esperance, Western Australia", label: "Esperance, Western Australia" },
  { value: "Espumilla Beach, Santiago Island", label: "Espumilla Beach, Santiago Island" },
  { value: "Esztergom", label: "Esztergom" },
  { value: "Evighedsfjorden", label: "Evighedsfjorden" },
  { value: "Exmouth", label: "Exmouth" },
  { value: "Fakarava, Tuamotu Islands", label: "Fakarava, Tuamotu Islands" },
  { value: "Falkland Islands", label: "Falkland Islands" },
  { value: "Falmouth Harbour,Antigua", label: "Falmouth Harbour,Antigua" },
  { value: "Falmouth,Jamaica", label: "Falmouth,Jamaica" },
  { value: "Falmouth,United Kingdom", label: "Falmouth,United Kingdom" },
  { value: "Fanning Island", label: "Fanning Island" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fergusson Island, PNG", label: "Fergusson Island, PNG" },
  { value: "Ferrol", label: "Ferrol" },
  { value: "Fiordland National Park", label: "Fiordland National Park" },
  { value: "Fishguard", label: "Fishguard" },
  { value: "Flam", label: "Flam" },
  { value: "Floreana", label: "Floreana" },
  { value: "Florence/Pisa", label: "Florence/Pisa" },
  { value: "Fort de France", label: "Fort de France" },
  { value: "Fort Lauderdale", label: "Fort Lauderdale" },
  { value: "Fort William, Scotland", label: "Fort William, Scotland" },
  { value: "Fortaleza", label: "Fortaleza" },
  { value: "Fowey, Cornwall", label: "Fowey, Cornwall" },
  { value: "Foynes", label: "Foynes" },
  { value: "Frankfurt", label: "Frankfurt" },
  { value: "Fredericia", label: "Fredericia" },
  { value: "Frederikshab", label: "Frederikshab" },
  { value: "Frederiksted,St. Croix", label: "Frederiksted,St. Croix" },
  { value: "Fredrikstad (for Oslo)", label: "Fredrikstad (for Oslo)" },
  { value: "Freeport", label: "Freeport" },
  { value: "Frejus, France", label: "Frejus, France" },
  { value: "Fremantle", label: "Fremantle" },
  { value: "Fuerte Amador", label: "Fuerte Amador" },
  { value: "Fuerteventura", label: "Fuerteventura" },
  { value: "Fujairah", label: "Fujairah" },
  { value: "Fukuoka", label: "Fukuoka" },
  { value: "Funchal", label: "Funchal" },
  { value: "Gaeta", label: "Gaeta" },
  { value: "Gaferut, Yap, Micronesia, Federated States", label: "Gaferut, Yap, Micronesia,Federated States" },
  { value: "Galle", label: "Galle" },
  { value: "Gallipoli", label: "Gallipoli" },
  { value: "Galveston", label: "Galveston" },
  { value: "Galway", label: "Galway" },
  { value: "Gardner Bay, Espanola Island", label: "Gardner Bay, Espanola Island" },
  { value: "Garove Island (Witu Islands)", label: "Garove Island (Witu Islands)" },
  { value: "Gaspe", label: "Gaspe" },
  { value: "Gdansk", label: "Gdansk" },
  { value: "Gdynia", label: "Gdynia" },
  { value: "Geelong (Melbourne)", label: "Geelong (Melbourne)" },
  { value: "Geiranger", label: "Geiranger" },
  { value: "Geirangerfjord", label: "Geirangerfjord" },
  { value: "Genoa", label: "Genoa" },
  { value: "Geographic Harbor, Alaska", label: "Geographic Harbor, Alaska" },
  { value: "George Town, Cayman Islands", label: "George Town, Cayman Islands" },
  { value: "Georgetown, Ascension Island", label: "Georgetown, Ascension Island" },
  { value: "Georgetown, Penang", label: "Georgetown, Penang" },
  { value: "Geraldton, Western Australia", label: "Geraldton, Western Australia" },
  { value: "Ghent", label: "Ghent" },
  { value: "Giardini Naxos, Sicily", label: "Giardini Naxos, Sicily" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Gijon", label: "Gijon" },
  { value: "Gisborne", label: "Gisborne" },
  { value: "Giurgiu", label: "Giurgiu" },
  { value: "Gjesvaerstappan, Norway", label: "Gjesvaerstappan, Norway" },
  { value: "Glacier Bay", label: "Glacier Bay" },
  { value: "Gladstone, Australia", label: "Gladstone, Australia" },
  { value: "Glasgow", label: "Glasgow" },
  { value: "Glengariff", label: "Glengariff" },
  { value: "Gloucester, MA", label: "Gloucester, MA" },
  { value: "Goa", label: "Goa" },
  { value: "Golfito", label: "Golfito" },
  { value: "Golfo Aranci, Sardinia", label: "Golfo Aranci, Sardinia" },
  { value: "Golfo Dulce", label: "Golfo Dulce" },
  { value: "Goteborg", label: "Goteborg" },
  { value: "Gothenburg", label: "Gothenburg" },
  { value: "Gough Island", label: "Gough Island" },
  { value: "Gran Canaria", label: "Gran Canaria" },
  { value: "Granada", label: "Granada" },
  { value: "Grand Bahama Island", label: "Grand Bahama Island" },
  { value: "Grand Cayman", label: "Grand Cayman" },
  { value: "Grand Turk", label: "Grand Turk" },
  { value: "Gravdal,Lofoten Isles", label: "Gravdal,Lofoten Isles" },
  { value: "Great Barrier Reef", label: "Great Barrier Reef" },
  { value: "Great Stirrup Cay", label: "Great Stirrup Cay" },
  { value: "Greenock (Glasgow)", label: "Greenock (Glasgow)" },
  { value: "Grenada, Windward Islands,Grenadines", label: "Grenada, Windward Islands, Grenadines" },
  { value: "Grundafjordur", label: "Grundafjordur" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guayaquil", label: "Guayaquil" },
  { value: "Gudvangen", label: "Gudvangen" },
  { value: "Guernsey, UK", label: "Guernsey, UK" },
  { value: "Gustavia", label: "Gustavia" },
  { value: "Gydnia", label: "Gydnia" },
  { value: "Gythion", label: "Gythion" },
  { value: "Ha Long Bay", label: "Ha Long Bay" },
  { value: "Hagi", label: "Hagi" },
  { value: "Haifa", label: "Haifa" },
  { value: "Haines, Alaska", label: "Haines, Alaska" },
  { value: "Hakodate", label: "Hakodate" },
  { value: "Half Moon Cay", label: "Half Moon Cay" },
  { value: "Halifax", label: "Halifax" },
  { value: "Hall Island, Alaska", label: "Hall Island, Alaska" },
  { value: "Hambantota", label: "Hambantota" },
  { value: "Hamburg", label: "Hamburg" },
  { value: "Hamilton Island, Whitsundays", label: "Hamilton Island, Whitsundays" },
  { value: "Hamilton, Bermuda", label: "Hamilton, Bermuda" },
  { value: "Hamilton, USA", label: "Hamilton, USA" },
  { value: "Hammerfest", label: "Hammerfest" },
  { value: "Hanga Roa, Easter Island", label: "Hanga Roa, Easter Island" },
  { value: "Hanoi", label: "Hanoi" },
  { value: "Harlingen", label: "Harlingen" },
  { value: "Harstad", label: "Harstad" },
  { value: "Harvest Caye, Belize", label: "Harvest Caye, Belize" },
  { value: "Harwich", label: "Harwich" },
  { value: "Haugesund", label: "Haugesund" },
  { value: "Havana", label: "Havana" },
  { value: "Havere St.Pierre", label: "Havere St.Pierre" },
  { value: "Havre de Robert", label: "Havre de Robert" },
  { value: "Havre St Pierre", label: "Havre St Pierre" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Heimaey", label: "Heimaey" },
  { value: "Hellesylt", label: "Hellesylt" },
  { value: "Hellville (Nosy Be)", label: "Hellville (Nosy Be)" },
  { value: "Helsingborg", label: "Helsingborg" },
  { value: "Helsinki", label: "Helsinki" },
  { value: "Hendaye", label: "Hendaye" },
  { value: "Henderson Island, Pitcairn Islands", label: "Henderson Island, Pitcairn Islands" },
  { value: "Heraklion", label: "Heraklion" },
  { value: "Hilo", label: "Hilo" },
  { value: "Hiroshima", label: "Hiroshima" },
  { value: "Ho Chi Minh City", label: "Ho Chi Minh City" },
  { value: "Hobart", label: "Hobart" },
  { value: "Hoi An", label: "Hoi An" },
  { value: "Holgate Glacier, Alaska", label: "Holgate Glacier, Alaska" },
  { value: "Holyhead, UK", label: "Holyhead, UK" },
  { value: "Homer, Alaska", label: "Homer, Alaska" },
  { value: "Honfleur", label: "Honfleur" },
  { value: "Hong Kong Kai Tak", label: "Hong Kong Kai Tak" },
  { value: "Hong Kong Ocean Terminal", label: "Hong Kong Ocean Terminal" },
  { value: "Honiara", label: "Honiara" },
  { value: "Honningsvaag", label: "Honningsvaag" },
  { value: "Honningsvag - North Cape", label: "Honningsvag - North Cape" },
  { value: "Honolulu", label: "Honolulu" },
  { value: "Hoonah", label: "Hoonah" },
  { value: "Hoorn", label: "Hoorn" },
  { value: "Horta", label: "Horta" },
  { value: "Hososhima", label: "Hososhima" },
  { value: "Huahine", label: "Huahine" },
  { value: "Hualien", label: "Hualien" },
  { value: "Huatalco", label: "Huatalco" },
  { value: "Huatulco, Mexico", label: "Huatulco, Mexico" },
  { value: "Hubbard Glacier", label: "Hubbard Glacier" },
  { value: "Hue/Danang (Chan May)", label: "Hue/Danang (Chan May)" },
  { value: "Hull", label: "Hull" },
  { value: "Hundred Islands", label: "Hundred Islands" },
  { value: "Hunter River, Western Australia", label: "Hunter River, Western Australia" },
  { value: "Husavik", label: "Husavik" },
  { value: "Hvalsey", label: "Hvalsey" },
  { value: "Hvar", label: "Hvar" },
  { value: "Hydra", label: "Hydra" },
  { value: "Ibiza", label: "Ibiza" },
  { value: "Icy Strait", label: "Icy Strait" },
  { value: "Igoumenitsa", label: "Igoumenitsa" },
  { value: "Iguazu Falls", label: "Iguazu Falls" },
  { value: "IIheus", label: "IIheus" },
  { value: "IIulissat", label: "IIulissat" },
  { value: "Ijmuiden (for Amsterdam)", label: "Ijmuiden (for Amsterdam)" },
  { value: "Ijmuiden", label: "Ijmuiden" },
  { value: "Ile Rousse, Corsica", label: "Ile Rousse, Corsica" },
  { value: "Iles de la Madeleine, Quebec", label: "Iles de la Madeleine, Quebec" },
  { value: "Ilfracombe", label: "Ilfracombe" },
  { value: "Ilha Grande", label: "Ilha Grande" },
  { value: "Ilhabela (Sao Sebastiao)", label: "Ilhabela (Sao Sebastiao)" },
  { value: "Ilhabela", label: "Ilhabela" },
  { value: "Ilheus", label: "Ilheus" },
  { value: "Ililussat", label: "Ililussat" },
  { value: "Incheon", label: "Incheon" },
  { value: "Inian Island, Alaska", label: "Inian Island, Alaska" },
  { value: "Invergordon", label: "Invergordon" },
  { value: "Inverness", label: "Inverness" },
  { value: "Iona", label: "Iona" },
  { value: "Iqaluit", label: "Iqaluit" },
  { value: "Iquique", label: "Iquique" },
  { value: "Iraklion", label: "Iraklion" },
  { value: "Isafjordur", label: "Isafjordur" },
  { value: "Isfjord", label: "Isfjord" },
  { value: "Ishigaki Island", label: "Ishigaki Island" },
  { value: "Isla Chanaral", label: "Isla Chanaral" },
  { value: "Isla de La Plata", label: "Isla de La Plata" },
  { value: "Isla de Margarita", label: "Isla de Margarita" },
  { value: "Isla de Roatan, Honduras", label: "Isla de Roatan, Honduras" },
  { value: "Isla Robinson Crusoe", label: "Isla Robinson Crusoe" },
  { value: "Isle of Pines", label: "Isle of Pines" },
  { value: "Isle of Skye", label: "Isle of Skye" },
  { value: "Istanbul", label: "Istanbul" },
  { value: "Itilleq", label: "Itilleq" },
  { value: "Ittoqqortoormiit", label: "Ittoqqortoormiit" },
  { value: "Jacksonville", label: "Jacksonville" },
  { value: "Jakarta", label: "Jakarta" },
  { value: "Jamestown, St Helena", label: "Jamestown, St Helena" },
  { value: "Java, Indonesia", label: "Java, Indonesia" },
  { value: "Jayapura", label: "Jayapura" },
  { value: "Jeju", label: "Jeju" },
  { value: "Jerusalem", label: "Jerusalem" },
  { value: "Jost Van Dycke", label: "Jost Van Dycke" },
  { value: "Juneau", label: "Juneau" },
  { value: "Kagoshima", label: "Kagoshima" },
  { value: "Kahalui", label: "Kahalui" },
  { value: "Kaikoura", label: "Kaikoura" },
  { value: "Kailua Kona", label: "Kailua Kona" },
  { value: "Kalabahi (Takpala)", label: "Kalabahi (Takpala)" },
  { value: "Kalamata", label: "Kalamata" },
  { value: "Kalocsa", label: "Kalocsa" },
  { value: "Kampen, Germany", label: "Kampen, Germany" },
  { value: "Kanazawa", label: "Kanazawa" },
  { value: "Kangaamiut", label: "Kangaamiut" },
  { value: "Kangaroo Island", label: "Kangaroo Island" },
  { value: "Kangerlussuaq", label: "Kangerlussuaq" },
  { value: "Kangiqsualujjuaq", label: "Kangiqsualujjuaq" },
  { value: "Kanmon Straits, Japan", label: "Kanmon Straits, Japan" },
  { value: "Kaohsiung", label: "Kaohsiung" },
  { value: "Karimunjawa", label: "Karimunjawa" },
  { value: "Karlsruhe", label: "Karlsruhe" },
  { value: "Katakolon", label: "Katakolon" },
  { value: "Kauai (Nawiliwili)", label: "Kauai (Nawiliwili)" },
  { value: "Kavala", label: "Kavala" },
  { value: "Kehl", label: "Kehl" },
  { value: "Kerkira, Corfu", label: "Kerkira, Corfu" },
  { value: "Kerkyra (Corfu)", label: "Kerkyra (Corfu)" },
  { value: "Ketchikan", label: "Ketchikan" },
  { value: "Key West", label: "Key West" },
  { value: "Khasab", label: "Khasab" },
  { value: "Khor al Fakkan, UAE", label: "Khor al Fakkan, UAE" },
  { value: "Kicker Rock, San Cristobal", label: "Kicker Rock, San Cristobal" },
  { value: "Kiel Canal", label: "Kiel Canal" },
  { value: "Kiel", label: "Kiel" },
  { value: "Killybegs", label: "Killybegs" },
  { value: "King George Falls, Western Australia", label: "King George Falls, WesternAustralia" },
  { value: "King's Wharf, Bermuda", label: "King's Wharf, Bermuda" },
  { value: "Kingfisher Islands", label: "Kingfisher Islands" },
  { value: "Kingston", label: "Kingston" },
  { value: "Kingstown", label: "Kingstown" },
  { value: "Kiriwina Island", label: "Kiriwina Island" },
  { value: "Kiriwina/Kaileuna,Trobriands", label: "Kiriwina/Kaileuna,Trobriands" },
  { value: "Kirkwall, Orkney Islands", label: "Kirkwall, Orkney Islands" },
  { value: "KISKA HARBOR, ALASKA", label: "KISKA HARBOR, ALASKA" },
  { value: "Kitava Island, PNG", label: "Kitava Island, PNG" },
  { value: "Klaipeda", label: "Klaipeda" },
  { value: "Klaksvik", label: "Klaksvik" },
  { value: "Klemtu", label: "Klemtu" },
  { value: "Ko Kood", label: "Ko Kood" },
  { value: "Ko Samui", label: "Ko Samui" },
  { value: "Kobe", label: "Kobe" },
  { value: "Koblenz", label: "Koblenz" },
  { value: "Kochi, India", label: "Kochi, India" },
  { value: "Kochi, Japan", label: "Kochi, Japan" },
  { value: "Kodiak, Alaska", label: "Kodiak, Alaska" },
  { value: "Komodo Island", label: "Komodo Island" },
  { value: "Kona", label: "Kona" },
  { value: "Koper", label: "Koper" },
  { value: "Korcula", label: "Korcula" },
  { value: "Korsakov", label: "Korsakov" },
  { value: "Kos", label: "Kos" },
  { value: "Kota Kinabalu", label: "Kota Kinabalu" },
  { value: "Kotor", label: "Kotor" },
  { value: "Kralendijk,Bonaire", label: "Kralendijk,Bonaire" },
  { value: "Krems", label: "Krems" },
  { value: "Kristiansand, Norway", label: "Kristiansand, Norway" },
  { value: "Kristiansund", label: "Kristiansund" },
  { value: "Kuala Lumpur", label: "Kuala Lumpur" },
  { value: "Kuantan", label: "Kuantan" },
  { value: "Kuching", label: "Kuching" },
  { value: "Kukak Bay, Alaska", label: "Kukak Bay, Alaska" },
  { value: "Kumai/Tanjung Puting National Park", label: "Kumai/Tanjung Puting National Park" },
  { value: "Kumamoto", label: "Kumamoto" },
  { value: "Kumano, Japan", label: "Kumano, Japan" },
  { value: "Kupang", label: "Kupang" },
  { value: "Kuri Bay", label: "Kuri Bay" },
  { value: "Kusadasi", label: "Kusadasi" },
  { value: "Kushiro, Japan", label: "Kushiro, Japan" },
  { value: "Kuto, Ile des Pins, New Caledonia", label: "Kuto, Ile des Pins, New Caledonia" },
  { value: "Kyoto", label: "Kyoto" },
  { value: "L'Anse aux Meadows", label: "L'Anse aux Meadows" },
  { value: "La Coruna", label: "La Coruna" },
  { value: "La Digue", label: "La Digue" },
  { value: "La Gomera (Canary Islands)", label: "La Gomera (Canary Islands)" },
  { value: "La Habana", label: "La Habana" },
  { value: "La Palma, Canary Islands", label: "La Palma, Canary Islands" },
  { value: "La Paz", label: "La Paz" },
  { value: "La Possesion", label: "La Possesion" },
  { value: "La Rochelle", label: "La Rochelle" },
  { value: "La Romana", label: "La Romana" },
  { value: "La Spezia", label: "La Spezia" },
  { value: "Labadee", label: "Labadee" },
  { value: "Labrador", label: "Labrador" },
  { value: "Lae", label: "Lae" },
  { value: "Laem Chabang", label: "Laem Chabang" },
  { value: "Laguna San Rafael", label: "Laguna San Rafael" },
  { value: "Lahaina", label: "Lahaina" },
  { value: "Langkawi", label: "Langkawi" },
  { value: "Lanzarote", label: "Lanzarote" },
  { value: "Larnaca", label: "Larnaca" },
  { value: "Las Bachas, Santa Cruz", label: "Las Bachas, Santa Cruz" },
  { value: "Las Palmas,Gran Canaria", label: "Las Palmas,Gran Canaria" },
  { value: "Lautoka", label: "Lautoka" },
  { value: "Lavrion", label: "Lavrion" },
  { value: "Le Havre", label: "Le Havre" },
  { value: "Le Lavendou", label: "Le Lavendou" },
  { value: "Le Palais", label: "Le Palais" },
  { value: "Le Piree", label: "Le Piree" },
  { value: "Le Port", label: "Le Port" },
  { value: "Le Verdon", label: "Le Verdon" },
  { value: "Leith, Scotland", label: "Leith, Scotland" },
  { value: "Leixoes", label: "Leixoes" },
  { value: "Leknes, Lofoten", label: "Leknes, Lofoten" },
  { value: "Lelystad", label: "Lelystad" },
  { value: "Lerwick", label: "Lerwick" },
  { value: "Les Andelys", label: "Les Andelys" },
  { value: "Les Saints", label: "Les Saints" },
  { value: "Lesbos", label: "Lesbos" },
  { value: "Leti Island", label: "Leti Island" },
  { value: "Levuka, Ovalau", label: "Levuka, Ovalau" },
  { value: "Lifou Island", label: "Lifou Island" },
  { value: "Lima. Peru", label: "Lima. Peru" },
  { value: "Limassol", label: "Limassol" },
  { value: "Limbe", label: "Limbe" },
  { value: "Limon", label: "Limon" },
  { value: "Linz (Salzburg)", label: "Linz (Salzburg)" },
  { value: "Lipari Islands", label: "Lipari Islands" },
  { value: "Lipari", label: "Lipari" },
  { value: "Lisbon", label: "Lisbon" },
  { value: "Little Boydong Reef", label: "Little Boydong Reef" },
  { value: "Liverpool", label: "Liverpool" },
  { value: "Livorno", label: "Livorno" },
  { value: "Lizard Island", label: "Lizard Island" },
  { value: "Ljubljana, Slovenia (Koper)", label: "Ljubljana, Slovenia (Koper)" },
  { value: "Lobito", label: "Lobito" },
  { value: "Lofoten Islands", label: "Lofoten Islands" },
  { value: "Lomblem Island, Indonesia", label: "Lomblem Island, Indonesia" },
  { value: "Lombok", label: "Lombok" },
  { value: "Lome", label: "Lome" },
  { value: "London Tower Bridge", label: "London Tower Bridge" },
  { value: "London", label: "London" },
  { value: "London, Greenwich", label: "London, Greenwich" },
  { value: "Londonderry", label: "Londonderry" },
  { value: "Long Beach Cruise Terminal", label: "Long Beach Cruise Terminal" },
  { value: "Longyearbyen", label: "Longyearbyen" },
  { value: "Loreto", label: "Loreto" },
  { value: "Lorient", label: "Lorient" },
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "Los Gemelos, Santa Cruz, Ecuador", label: "Los Gemelos, Santa Cruz, Ecuador" },
  { value: "Louisbourg", label: "Louisbourg" },
  { value: "Luanda", label: "Luanda" },
  { value: "Lubeck", label: "Lubeck" },
  { value: "Luderitz", label: "Luderitz" },
  { value: "Luganville", label: "Luganville" },
  { value: "Lunga, Trishnish Island", label: "Lunga, Trishnish Island" },
  { value: "Luxor", label: "Luxor" },
  { value: "Lyon", label: "Lyon" },
  { value: "Lyttelton", label: "Lyttelton" },
  { value: "Macapa", label: "Macapa" },
  { value: "Maceio", label: "Maceio" },
  { value: "Machala, Ecuador", label: "Machala, Ecuador" },
  { value: "Macon", label: "Macon" },
  { value: "Madang", label: "Madang" },
  { value: "Madeira", label: "Madeira" },
  { value: "Mahahual", label: "Mahahual" },
  { value: "Mahe", label: "Mahe" },
  { value: "Maheskali, Bangladesh", label: "Maheskali, Bangladesh" },
  { value: "Mahogany Bay", label: "Mahogany Bay" },
  { value: "Mahon", label: "Mahon" },
  { value: "Mainz", label: "Mainz" },
  { value: "Maizuru", label: "Maizuru" },
  { value: "Majorca, Spain", label: "Majorca, Spain" },
  { value: "Makassar, Sulawesi", label: "Makassar, Sulawesi" },
  { value: "Makura Island, Vanuatu", label: "Makura Island, Vanuatu" },
  { value: "Malacca", label: "Malacca" },
  { value: "Malaga", label: "Malaga" },
  { value: "Maldives", label: "Maldives" },
  { value: "Male", label: "Male" },
  { value: "Malta (La Valletta)", label: "Malta (La Valletta)" },
  { value: "Malta", label: "Malta" },
  { value: "Manama", label: "Manama" },
  { value: "Manaus", label: "Manaus" },
  { value: "Mangalore", label: "Mangalore" },
  { value: "Maniitsoq", label: "Maniitsoq" },
  { value: "Manila", label: "Manila" },
  { value: "Mannheim", label: "Mannheim" },
  { value: "Manta", label: "Manta" },
  { value: "Manzanillo", label: "Manzanillo" },
  { value: "Maputo", label: "Maputo" },
  { value: "Mare", label: "Mare" },
  { value: "Margaret River, Australia", label: "Margaret River, Australia" },
  { value: "Mariehamn", label: "Mariehamn" },
  { value: "Marigot, Saint Martin", label: "Marigot, Saint Martin" },
  { value: "Marina di Carrara", label: "Marina di Carrara" },
  { value: "Marseille", label: "Marseille" },
  { value: "Martha's Vineyard, Massachusetts", label: "Martha's Vineyard, Massachusetts" },
  { value: "Martinique", label: "Martinique" },
  { value: "Matarani", label: "Matarani" },
  { value: "Matsuyama", label: "Matsuyama" },
  { value: "Maui (Lahaina), Hawaii", label: "Maui (Lahaina), Hawaii" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mayreau", label: "Mayreau" },
  { value: "Mazatlán", label: "Mazatlán" },
  { value: "Melbourne", label: "Melbourne" },
  { value: "Melk", label: "Melk" },
  { value: "Messina", label: "Messina" },
  { value: "Metlakatla", label: "Metlakatla" },
  { value: "Mgarr (Victoria)", label: "Mgarr (Victoria)" },
  { value: "Miami", label: "Miami" },
  { value: "Middelburg", label: "Middelburg" },
  { value: "Milford Haven (Milford Haven) - UK", label: "Milford Haven (Milford Haven) - UK" },
  { value: "Milford Sound", label: "Milford Sound" },
  { value: "Milos", label: "Milos" },
  { value: "Miltenberg", label: "Miltenberg" },
  { value: "Mina Qabos, UAE", label: "Mina Qabos, UAE" },
  { value: "Mindelo, Cape Verde Is.", label: "Mindelo, Cape Verde Is." },
  { value: "Miquelon", label: "Miquelon" },
  { value: "Misty Fjords, Alaska", label: "Misty Fjords, Alaska" },
  { value: "Mitilini (Lesvos)", label: "Mitilini (Lesvos)" },
  { value: "Miyajima Island", label: "Miyajima Island" },
  { value: "Miyake-jima", label: "Miyake-jima" },
  { value: "Miyanoura - Yakushima", label: "Miyanoura - Yakushima" },
  { value: "Miyazaki", label: "Miyazaki" },
  { value: "Mobile, AL", label: "Mobile, AL" },
  { value: "Mohacs", label: "Mohacs" },
  { value: "Moji/Kitakyushu", label: "Moji/Kitakyushu" },
  { value: "Molde", label: "Molde" },
  { value: "Mombasa", label: "Mombasa" },
  { value: "Monaco", label: "Monaco" },
  { value: "Monemvasia", label: "Monemvasia" },
  { value: "Monte Carlo", label: "Monte Carlo" },
  { value: "Montego Bay", label: "Montego Bay" },
  { value: "Monterey", label: "Monterey" },
  { value: "Montevideo", label: "Montevideo" },
  { value: "Montreal", label: "Montreal" },
  { value: "Monumental Island", label: "Monumental Island" },
  { value: "Mooloolaba, Queensland", label: "Mooloolaba, Queensland" },
  { value: "Moorea", label: "Moorea" },
  { value: "Moreton Island, Australia", label: "Moreton Island, Australia" },
  { value: "Mormugao, Goa", label: "Mormugao, Goa" },
  { value: "Moskito Island, BVI", label: "Moskito Island, BVI" },
  { value: "Mosquera Island", label: "Mosquera Island" },
  { value: "Mossel Bay", label: "Mossel Bay" },
  { value: "Motril, Granada", label: "Motril, Granada" },
  { value: "Mount Kilauea", label: "Mount Kilauea" },
  { value: "Mt Fuji (Shimizu)", label: "Mt Fuji (Shimizu)" },
  { value: "Muara, Brunei", label: "Muara, Brunei" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Murmansk", label: "Murmansk" },
  { value: "Muroran", label: "Muroran" },
  { value: "Muscat", label: "Muscat" },
  { value: "Mykonos", label: "Mykonos" },
  { value: "Mystery Island", label: "Mystery Island" },
  { value: "Nafplion", label: "Nafplion" },
  { value: "Nagasaki", label: "Nagasaki" },
  { value: "Nagoya", label: "Nagoya" },
  { value: "Naha", label: "Naha" },
  { value: "Nanaimo", label: "Nanaimo" },
  { value: "Nanortalik", label: "Nanortalik" },
  { value: "Napier", label: "Napier" },
  { value: "Naples", label: "Naples" },
  { value: "Narssarssuaq", label: "Narssarssuaq" },
  { value: "Narvik", label: "Narvik" },
  { value: "Nassau", label: "Nassau" },
  { value: "Natal", label: "Natal" },
  { value: "Nathon", label: "Nathon" },
  { value: "Nauplion", label: "Nauplion" },
  { value: "Navplion", label: "Navplion" },
  { value: "Nawiliwili", label: "Nawiliwili" },
  { value: "Neiafu", label: "Neiafu" },
  { value: "Nelson", label: "Nelson" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Island", label: "New Island" },
  { value: "New Mangalore", label: "New Mangalore" },
  { value: "New Orleans", label: "New Orleans" },
  { value: "New York", label: "New York" },
  { value: "Newcastle, NSW", label: "Newcastle, NSW" },
  { value: "Newcastle, UK", label: "Newcastle, UK" },
  { value: "Newhaven", label: "Newhaven" },
  { value: "Newport, Rhode Island", label: "Newport, Rhode Island" },
  { value: "Newport,UK", label: "Newport,UK" },
  { value: "Nha Trang", label: "Nha Trang" },
  { value: "Nice (Villefranche)", label: "Nice (Villefranche)" },
  { value: "Nierstein", label: "Nierstein" },
  { value: "Nightingale Island", label: "Nightingale Island" },
  { value: "Niigata", label: "Niigata" },
  { value: "Nijmegen", label: "Nijmegen" },
  { value: "Nome, Alaska", label: "Nome, Alaska" },
  { value: "Nordfjordeid", label: "Nordfjordeid" },
  { value: "Norfolk Island, Australia", label: "Norfolk Island, Australia" },
  { value: "Norfolk, USA", label: "Norfolk, USA" },
  { value: "North Cape", label: "North Cape" },
  { value: "North Seymour", label: "North Seymour" },
  { value: "Nosey Be", label: "Nosey Be" },
  { value: "Noumea", label: "Noumea" },
  { value: "Novi Sad", label: "Novi Sad" },
  { value: "Nuku Hiva", label: "Nuku Hiva" },
  { value: "NukuAlofa", label: "NukuAlofa" },
  { value: "Nunavut Canada", label: "Nunavut Canada" },
  { value: "Nuremberg", label: "Nuremberg" },
  { value: "Nuuk", label: "Nuuk" },
  { value: "Ny Alesund", label: "Ny Alesund" },
  { value: "Nydri", label: "Nydri" },
  { value: "Nynashamn", label: "Nynashamn" },
  { value: "Oban (Halfmoon Bay), New Zealand", label: "Oban (Halfmoon Bay), New Zealand" },
  { value: "Oban, UK", label: "Oban, UK" },
  { value: "Ocean Cay MSC Marine Reserve", label: "Ocean Cay MSC Marine Reserve" },
  { value: "Ocho Rios", label: "Ocho Rios" },
  { value: "Okayama", label: "Okayama" },
  { value: "Okinawa", label: "Okinawa" },
  { value: "Olbia/Sardinia", label: "Olbia/Sardinia" },
  { value: "Olden", label: "Olden" },
  { value: "Oltenita", label: "Oltenita" },
  { value: "Olympia, Greece", label: "Olympia, Greece" },
  { value: "Opatija", label: "Opatija" },
  { value: "Oporto", label: "Oporto" },
  { value: "Oranjestad, Aruba", label: "Oranjestad, Aruba" },
  { value: "Orkney Islands", label: "Orkney Islands" },
  { value: "Orlando", label: "Orlando" },
  { value: "Osaka", label: "Osaka" },
  { value: "Oslo", label: "Oslo" },
  { value: "Ostende", label: "Ostende" },
  { value: "Otaru, Japan", label: "Otaru, Japan" },
  { value: "Padang Bay,Bali", label: "Padang Bay,Bali" },
  { value: "Pagan", label: "Pagan" },
  { value: "Pago Pago", label: "Pago Pago" },
  { value: "Paita", label: "Paita" },
  { value: "Palamos", label: "Palamos" },
  { value: "Palermo", label: "Palermo" },
  { value: "Palma de Majorca", label: "Palma de Majorca" },
  { value: "Palma De Mallorca", label: "Palma De Mallorca" },
  { value: "Palma", label: "Palma" },
  { value: "Palmerston", label: "Palmerston" },
  { value: "Palopo, Sulawesi", label: "Palopo, Sulawesi" },
  { value: "Panama Canal", label: "Panama Canal" },
  { value: "Papeete", label: "Papeete" },
  { value: "Paphos", label: "Paphos" },
  { value: "Paracas", label: "Paracas" },
  { value: "Parati", label: "Parati" },
  { value: "Paraty", label: "Paraty" },
  { value: "Pare Pare", label: "Pare Pare" },
  { value: "Parga", label: "Parga" },
  { value: "Parintins", label: "Parintins" },
  { value: "Paris", label: "Paris" },
  { value: "Paros", label: "Paros" },
  { value: "Passau (Munich)", label: "Passau (Munich)" },
  { value: "Pasuruan, Indonesia", label: "Pasuruan, Indonesia" },
  { value: "Patmos", label: "Patmos" },
  { value: "Pembroke, Wales", label: "Pembroke, Wales" },
  { value: "Penang", label: "Penang" },
  { value: "Penneshaw, Kangaroo Island", label: "Penneshaw, Kangaroo Island" },
  { value: "Pentecost Island", label: "Pentecost Island" },
  { value: "Petersburg, Alaska", label: "Petersburg, Alaska" },
  { value: "Petra", label: "Petra" },
  { value: "Petropavlovsk", label: "Petropavlovsk" },
  { value: "Philipsburg, Puerto Rico", label: "Philipsburg, Puerto Rico" },
  { value: "Philipsburg, St Maarten", label: "Philipsburg, St Maarten" },
  { value: "Phillip Island", label: "Phillip Island" },
  { value: "Phu My", label: "Phu My" },
  { value: "Phu Quoc", label: "Phu Quoc" },
  { value: "Phuket", label: "Phuket" },
  { value: "Picton, NZ", label: "Picton, NZ" },
  { value: "Pine Island", label: "Pine Island" },
  { value: "Pinhao, Portugal", label: "Pinhao, Portugal" },
  { value: "Pink Beach, Komodo", label: "Pink Beach, Komodo" },
  { value: "Piombino", label: "Piombino" },
  { value: "Piran", label: "Piran" },
  { value: "Pisco", label: "Pisco" },
  { value: "Pitcairn Islands", label: "Pitcairn Islands" },
  { value: "Plazas Sur", label: "Plazas Sur" },
  { value: "Plymouth", label: "Plymouth" },
  { value: "Point-a-Pitre", label: "Point-a-Pitre" },
  { value: "Pointe des Galets", label: "Pointe des Galets" },
  { value: "Pomene, Mozambique", label: "Pomene, Mozambique" },
  { value: "Ponce", label: "Ponce" },
  { value: "Pond Inlet", label: "Pond Inlet" },
  { value: "Ponta Delgada, Azores", label: "Ponta Delgada, Azores" },
  { value: "Ponza", label: "Ponza" },
  { value: "Poole", label: "Poole" },
  { value: "Porbandar", label: "Porbandar" },
  { value: "Port Antonio", label: "Port Antonio" },
  { value: "Port Arthur, Tasmania", label: "Port Arthur, Tasmania" },
  { value: "Port Blair", label: "Port Blair" },
  { value: "Port Canaveral", label: "Port Canaveral" },
  { value: "Port Chalmers", label: "Port Chalmers" },
  { value: "Port Denarau", label: "Port Denarau" },
  { value: "Port Douglas", label: "Port Douglas" },
  { value: "Port Elizabeth (Grenadines)", label: "Port Elizabeth (Grenadines)" },
  { value: "Port Elizabeth, South Africa", label: "Port Elizabeth, South Africa" },
  { value: "Port Everglades", label: "Port Everglades" },
  { value: "Port Klang", label: "Port Klang" },
  { value: "Port Lincoln", label: "Port Lincoln" },
  { value: "Port Louis", label: "Port Louis" },
  { value: "Port Mahon", label: "Port Mahon" },
  { value: "Port Moresby", label: "Port Moresby" },
  { value: "Port of Spain", label: "Port of Spain" },
  { value: "Port Rashid, Dubai", label: "Port Rashid, Dubai" },
  { value: "Port Said", label: "Port Said" },
  { value: "Port Stanley", label: "Port Stanley" },
  { value: "Port Vendres", label: "Port Vendres" },
  { value: "Port Victoria, Seychelles", label: "Port Victoria, Seychelles" },
  { value: "Port Vila", label: "Port Vila" },
  { value: "Porta Praia", label: "Porta Praia" },
  { value: "Portimao", label: "Portimao" },
  { value: "Portland, Dorset", label: "Portland, Dorset" },
  { value: "Portland, Maine", label: "Portland, Maine" },
  { value: "Portland, UK", label: "Portland, UK" },
  { value: "Portland, USA", label: "Portland, USA" },
  { value: "Portland, Victoria", label: "Portland, Victoria" },
  { value: "Porto Belo, Brazil", label: "Porto Belo, Brazil" },
  { value: "Porto Empedocle, Sicily", label: "Porto Empedocle, Sicily" },
  { value: "Porto Malai, Langkawi", label: "Porto Malai, Langkawi" },
  { value: "Porto Novo, Cape Verde Isles", label: "Porto Novo, Cape Verde Isles" },
  { value: "Porto Santo (Madeira)", label: "Porto Santo (Madeira)" },
  { value: "Porto Santo Stefano", label: "Porto Santo Stefano" },
  { value: "Porto Torres", label: "Porto Torres" },
  { value: "Porto Vecchio, Corsica", label: "Porto Vecchio, Corsica" },
  { value: "Porto Venere", label: "Porto Venere" },
  { value: "Porto", label: "Porto" },
  { value: "Portoferraio (Elba Isl.)", label: "Portoferraio (Elba Isl.)" },
  { value: "Portoferraio,Elba", label: "Portoferraio,Elba" },
  { value: "Portofino", label: "Portofino" },
  { value: "Portovenere", label: "Portovenere" },
  { value: "Portree, Isle of Skye", label: "Portree, Isle of Skye" },
  { value: "Portrush", label: "Portrush" },
  { value: "Portsmouth,UK", label: "Portsmouth,UK" },
  { value: "Portuguese Islands", label: "Portuguese Islands" },
  { value: "Post Office , Galapagos", label: "Post Office , Galapagos" },
  { value: "Praia di Vitoria, Azores", label: "Praia di Vitoria, Azores" },
  { value: "Praia, Cape Verde", label: "Praia, Cape Verde" },
  { value: "Praslin Islands", label: "Praslin Islands" },
  { value: "Prince Christian Sound", label: "Prince Christian Sound" },
  { value: "Prince Rupert", label: "Prince Rupert" },
  { value: "Princess Cays", label: "Princess Cays" },
  { value: "Prins Christian Sund", label: "Prins Christian Sund" },
  { value: "Probolinggo, Java", label: "Probolinggo, Java" },
  { value: "Progreso", label: "Progreso" },
  { value: "Propriano", label: "Propriano" },
  { value: "Provence (La Joliette), France", label: "Provence (La Joliette), France" },
  { value: "Provence", label: "Provence" },
  { value: "Provideniya", label: "Provideniya" },
  { value: "Puerto Ayora, Galapagos", label: "Puerto Ayora, Galapagos" },
  { value: "Puerto Baquerizo, San Cristobal", label: "Puerto Baquerizo, San Cristobal" },
  { value: "Puerto Caldera", label: "Puerto Caldera" },
  { value: "Puerto Chacabuco", label: "Puerto Chacabuco" },
  { value: "Puerto Chiapas", label: "Puerto Chiapas" },
  { value: "Puerto Costa Maya", label: "Puerto Costa Maya" },
  { value: "Puerto de la Estaca, El Hierro", label: "Puerto de la Estaca, El Hierro" },
  { value: "Puerto Del Rosario, Fuerteventura", label: "Puerto Del Rosario, Fuerteventura" },
  { value: "Puerto Egas, Isla San Salvador", label: "Puerto Egas, Isla San Salvador" },
  { value: "Puerto Egas, Santiago Island", label: "Puerto Egas, Santiago Island" },
  { value: "Puerto Limon", label: "Puerto Limon" },
  { value: "Puerto Madryn", label: "Puerto Madryn" },
  { value: "Puerto Moin", label: "Puerto Moin" },
  { value: "Puerto Montt", label: "Puerto Montt" },
  { value: "Puerto Natales", label: "Puerto Natales" },
  { value: "Puerto Plata/Dominican Republic", label: "Puerto Plata/Dominican Republic" },
  { value: "Puerto Princessa, Palawan", label: "Puerto Princessa, Palawan" },
  { value: "Puerto Quepos", label: "Puerto Quepos" },
  { value: "Puerto Quetzal", label: "Puerto Quetzal" },
  { value: "Puerto Vallarta", label: "Puerto Vallarta" },
  { value: "Puerto Williams", label: "Puerto Williams" },
  { value: "Pula", label: "Pula" },
  { value: "Punta Arenas, Chile", label: "Punta Arenas, Chile" },
  { value: "Punta Cana, Domnican Republic", label: "Punta Cana, Domnican Republic" },
  { value: "Punta Cormorant, Isla Floreana", label: "Punta Cormorant, Isla Floreana" },
  { value: "Punta del Este", label: "Punta del Este" },
  { value: "Punta Espinoza, Fernandina Island", label: "Punta Espinoza, Fernandina Island" },
  { value: "Punta Moreno, Isabela Island", label: "Punta Moreno, Isabela Island" },
  { value: "Punta Suarez", label: "Punta Suarez" },
  { value: "Punta Vicente Roca, Isabela Island", label: "Punta Vicente Roca, Isabela Island" },
  { value: "Puntarenas, Costa Rica", label: "Puntarenas, Costa Rica" },
  { value: "Pusan", label: "Pusan" },
  { value: "Pylos", label: "Pylos" },
  { value: "Pyramiden, Norway", label: "Pyramiden, Norway" },
  { value: "Qaanaaq (Thule)", label: "Qaanaaq (Thule)" },
  { value: "Qaqortoq", label: "Qaqortoq" },
  { value: "Qassiarsuq (Brattalihd)", label: "Qassiarsuq (Brattalihd)" },
  { value: "Qeqertarsuaq, Godhavn", label: "Qeqertarsuaq, Godhavn" },
  { value: "Qingdao", label: "Qingdao" },
  { value: "Quebec", label: "Quebec" },
  { value: "Queen Charlotte Sound", label: "Queen Charlotte Sound" },
  { value: "Quepos", label: "Quepos" },
  { value: "Quito", label: "Quito" },
  { value: "Rab", label: "Rab" },
  { value: "Rabaul", label: "Rabaul" },
  { value: "Rabida Island, Galapagos Islands", label: "Rabida Island, Galapagos Islands" },
  { value: "Raiatea", label: "Raiatea" },
  { value: "Raivavae, Austral Islands", label: "Raivavae, Austral Islands" },
  { value: "Rangiroa", label: "Rangiroa" },
  { value: "Rangoon, Burma", label: "Rangoon, Burma" },
  { value: "Rarotonga", label: "Rarotonga" },
  { value: "Ravenna", label: "Ravenna" },
  { value: "Recife", label: "Recife" },
  { value: "Red Bay, Labrador", label: "Red Bay, Labrador" },
  { value: "Regensburg", label: "Regensburg" },
  { value: "Reggio Di Calabria", label: "Reggio Di Calabria" },
  { value: "Regua", label: "Regua" },
  { value: "Rethymnon, Crete", label: "Rethymnon, Crete" },
  { value: "Reunion", label: "Reunion" },
  { value: "Reykjavik", label: "Reykjavik" },
  { value: "Rhodes", label: "Rhodes" },
  { value: "Richard's Bay", label: "Richard's Bay" },
  { value: "Riga", label: "Riga" },
  { value: "Rijeka", label: "Rijeka" },
  { value: "Ringaskiddy (for Cork)", label: "Ringaskiddy (for Cork)" },
  { value: "Ringaskiddy", label: "Ringaskiddy" },
  { value: "Rio de Janeiro", label: "Rio de Janeiro" },
  { value: "Rio Grande", label: "Rio Grande" },
  { value: "Road Bay", label: "Road Bay" },
  { value: "Road Harbour, Tortola", label: "Road Harbour, Tortola" },
  { value: "Roadtown", label: "Roadtown" },
  { value: "Roadtown, Tortola", label: "Roadtown, Tortola" },
  { value: "Roatan, Bay Islands", label: "Roatan, Bay Islands" },
  { value: "Robinson Crueso Island", label: "Robinson Crueso Island" },
  { value: "Romblon Island", label: "Romblon Island" },
  { value: "Rome (Civitavecchia)", label: "Rome (Civitavecchia)" },
  { value: "Romsdalfjord", label: "Romsdalfjord" },
  { value: "Ronne, Bornholm", label: "Ronne, Bornholm" },
  { value: "Roseau", label: "Roseau" },
  { value: "Rosendal", label: "Rosendal" },
  { value: "Roses", label: "Roses" },
  { value: "Ross Island, Andaman Islands", label: "Ross Island, Andaman Islands" },
  { value: "Rostock", label: "Rostock" },
  { value: "Rosyth, Edinburgh", label: "Rosyth, Edinburgh" },
  { value: "Rothesay", label: "Rothesay" },
  { value: "Rotterdam", label: "Rotterdam" },
  { value: "Rouen (Paris)", label: "Rouen (Paris)" },
  { value: "Rousse", label: "Rousse" },
  { value: "Rovinj", label: "Rovinj" },
  { value: "Royal Naval Dockyard, Bermuda", label: "Royal Naval Dockyard, Bermuda" },
  { value: "Rudesheim", label: "Rudesheim" },
  { value: "Russel bay of islands", label: "Russel bay of islands" },
  { value: "Saba,Netherlands Antilles", label: "Saba,Netherlands Antilles" },
  { value: "Sabang", label: "Sabang" },
  { value: "Sabang, Pulau We", label: "Sabang, Pulau We" },
  { value: "Sado Island, Sea of Japan", label: "Sado Island, Sea of Japan" },
  { value: "Safaga", label: "Safaga" },
  { value: "Safi (Morroco)", label: "Safi (Morroco)" },
  { value: "Saguenay", label: "Saguenay" },
  { value: "Saigon", label: "Saigon" },
  { value: "Sail Island, Alaska", label: "Sail Island, Alaska" },
  { value: "Saint Florent (Corsica - France)", label: "Saint Florent (Corsica - France)" },
  { value: "Saint John,nb (bay Of Fundy)", label: "Saint John,nb (bay Of Fundy)" },
  { value: "Saint Raphael, France", label: "Saint Raphael, France" },
  { value: "Saint Tropez", label: "Saint Tropez" },
  { value: "Saint-Malo", label: "Saint-Malo" },
  { value: "Saipan", label: "Saipan" },
  { value: "Sakaiminato", label: "Sakaiminato" },
  { value: "Sakata", label: "Sakata" },
  { value: "Salalah", label: "Salalah" },
  { value: "Salaverry(Trujillo)", label: "Salaverry(Trujillo)" },
  { value: "Salerno", label: "Salerno" },
  { value: "Saline Bay, Mayreau, St. Vincent and the Grenadines", label: "Saline Bay, Mayreau, St. Vincent and the Grenadines" },
  { value: "Salvador", label: "Salvador" },
  { value: "Salvadore de Bahia", label: "Salvadore de Bahia" },
  { value: "Samana", label: "Samana" },
  { value: "Sami, Cephalonia, Greece", label: "Sami, Cephalonia, Greece" },
  { value: "San Andres, Colombia", label: "San Andres, Colombia" },
  { value: "San Antonio, Chile", label: "San Antonio, Chile" },
  { value: "San Blas", label: "San Blas" },
  { value: "San Cristobal Island, Galapagos Islands", label: "San Cristobal Island, Galapagos Islands" },
  { value: "San Cristobal, Venezuela", label: "San Cristobal, Venezuela" },
  { value: "San Diego, California", label: "San Diego, California" },
  { value: "San Francisco do Sul, Brazil", label: "San Francisco do Sul, Brazil" },
  { value: "San Francisco, California", label: "San Francisco, California" },
  { value: "San Juan del Sur", label: "San Juan del Sur" },
  { value: "San Juan", label: "San Juan" },
  { value: "San Martin", label: "San Martin" },
  { value: "San Remo", label: "San Remo" },
  { value: "San Sebastian, La Gomera", label: "San Sebastian, La Gomera" },
  { value: "Sanary-Sur-Mer", label: "Sanary-Sur-Mer" },
  { value: "Sandakan", label: "Sandakan" },
  { value: "Sangliat Dol Village, Yamdena Island", label: "Sangliat Dol Village, Yamdena Island" },
  { value: "Sant Cruz De La Palma", label: "Sant Cruz De La Palma" },
  { value: "Santa Ana", label: "Santa Ana" },
  { value: "Santa Barbara de la Samana", label: "Santa Barbara de la Samana" },
  { value: "Santa Barbara, California", label: "Santa Barbara, California" },
  { value: "Santa Cruz de la Palma, La Palma", label: "Santa Cruz de la Palma, La Palma" },
  { value: "Santa Cruz de Tenerife", label: "Santa Cruz de Tenerife" },
  { value: "Santa Cruz, Spain", label: "Santa Cruz, Spain" },
  { value: "Santa Fe Island, Galapagos", label: "Santa Fe Island, Galapagos" },
  { value: "Santa Margherita", label: "Santa Margherita" },
  { value: "Santa Marta", label: "Santa Marta" },
  { value: "Santander", label: "Santander" },
  { value: "Santarem", label: "Santarem" },
  { value: "Santiago de Chile", label: "Santiago de Chile" },
  { value: "Santiago de Compostela", label: "Santiago de Compostela" },
  { value: "Santiago de Cuba", label: "Santiago de Cuba" },
  { value: "Santiago", label: "Santiago" },
  { value: "Santo Domingo", label: "Santo Domingo" },
  { value: "Santo Thomas de Castilla", label: "Santo Thomas de Castilla" },
  { value: "Santo, Vanuatu", label: "Santo, Vanuatu" },
  { value: "Santorini Island", label: "Santorini Island" },
  { value: "Santos, Brazil", label: "Santos, Brazil" },
  { value: "Sanusavu", label: "Sanusavu" },
  { value: "Sanya", label: "Sanya" },
  { value: "Sao Paulo", label: "Sao Paulo" },
  { value: "Sao Tome", label: "Sao Tome" },
  { value: "Sao Vicente", label: "Sao Vicente" },
  { value: "Sapporo (Muroran/Hokkaido)", label: "Sapporo (Muroran/Hokkaido)" },
  { value: "Sarande", label: "Sarande" },
  { value: "Sasebo, Japan", label: "Sasebo, Japan" },
  { value: "Satawal, Yap, FSM", label: "Satawal, Yap, FSM" },
  { value: "Saunders Island", label: "Saunders Island" },
  { value: "Savona", label: "Savona" },
  { value: "Savusavu, Vanua Levi, Fiji", label: "Savusavu, Vanua Levi, Fiji" },
  { value: "Scarborough, Trinidad", label: "Scarborough, Trinidad" },
  { value: "Scarborough, UK", label: "Scarborough, UK" },
  { value: "Schweinfurt", label: "Schweinfurt" },
  { value: "Scorsbysund", label: "Scorsbysund" },
  { value: "Scrabster", label: "Scrabster" },
  { value: "Seattle", label: "Seattle" },
  { value: "Seguam Island, Alaska, USA", label: "Seguam Island, Alaska, USA" },
  { value: "Semarang", label: "Semarang" },
  { value: "Semidi, Alaska", label: "Semidi, Alaska" },
  { value: "Sendai", label: "Sendai" },
  { value: "Seoul (Incheon)", label: "Seoul (Incheon)" },
  { value: "Sept-Iles", label: "Sept-Iles" },
  { value: "Sete", label: "Sete" },
  { value: "Seville", label: "Seville" },
  { value: "Seward", label: "Seward" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Seydhisfjordur", label: "Seydhisfjordur" },
  { value: "Shanghai", label: "Shanghai" },
  { value: "Sharm El Sheikh", label: "Sharm El Sheikh" },
  { value: "Shimizu", label: "Shimizu" },
  { value: "Shimoneski", label: "Shimoneski" },
  { value: "Sibenik", label: "Sibenik" },
  { value: "Sicily", label: "Sicily" },
  { value: "Siglufjordur", label: "Siglufjordur" },
  { value: "Sihanoukville", label: "Sihanoukville" },
  { value: "Singapore Harbour Front", label: "Singapore Harbour Front" },
  { value: "Singapore Marina Bay", label: "Singapore Marina Bay" },
  { value: "Sir Bani Yas Island (UAE)", label: "Sir Bani Yas Island (UAE)" },
  { value: "Siracusa, Sicily", label: "Siracusa, Sicily" },
  { value: "Sisimiut Holsteinsborg", label: "Sisimiut Holsteinsborg" },
  { value: "Sitka", label: "Sitka" },
  { value: "Skagen", label: "Skagen" },
  { value: "Skagway", label: "Skagway" },
  { value: "Skarsvag", label: "Skarsvag" },
  { value: "Skellig Islands", label: "Skellig Islands" },
  { value: "Skiathos Town, Skiathos", label: "Skiathos Town, Skiathos" },
  { value: "Skjolden", label: "Skjolden" },
  { value: "Skjoldungen Island", label: "Skjoldungen Island" },
  { value: "Skomer Island", label: "Skomer Island" },
  { value: "Sognefjord", label: "Sognefjord" },
  { value: "Sokhna", label: "Sokhna" },
  { value: "Solovetsky Islands", label: "Solovetsky Islands" },
  { value: "Sorrento", label: "Sorrento" },
  { value: "Souda Bay", label: "Souda Bay" },
  { value: "Soufriere", label: "Soufriere" },
  { value: "South Cinque Island, Andaman Islands", label: "South Cinque Island, Andaman Islands" },
  { value: "South Georgia Islands, Antarctica", label: "South Georgia Islands, Antarctica" },
  { value: "South Plaza Island", label: "South Plaza Island" },
  { value: "South Queensferry", label: "South Queensferry" },
  { value: "Southampton", label: "Southampton" },
  { value: "Spanish Town / Prickly Pear", label: "Spanish Town / Prickly Pear" },
  { value: "Spanish Town, Virgin Gorda, BVI", label: "Spanish Town, Virgin Gorda, BVI" },
  { value: "Spetses", label: "Spetses" },
  { value: "Speyer", label: "Speyer" },
  { value: "Split", label: "Split" },
  { value: "St Barts", label: "St Barts" },
  { value: "St Barts, Gustavia", label: "St Barts, Gustavia" },
  { value: "St Croix", label: "St Croix" },
  { value: "St Florent", label: "St Florent" },
  { value: "St George, Romania", label: "St George, Romania" },
  { value: "St Georges, Bermuda", label: "St Georges, Bermuda" },
  { value: "St Georges, Grenada", label: "St Georges, Grenada" },
  { value: "St Helier", label: "St Helier" },
  { value: "St John", label: "St John" },
  { value: "St John's, Antigua", label: "St John's, Antigua" },
  { value: "St John's, Newfoundland", label: "St John's, Newfoundland" },
  { value: "St John, New Brunswick", label: "St John, New Brunswick" },
  { value: "St John, U.S.Virgin Islands", label: "St John, U.S.Virgin Islands" },
  { value: "St Maarten, French Antilles", label: "St Maarten, French Antilles" },
  { value: "St Malo", label: "St Malo" },
  { value: "St Mary’s, Scilly Isles", label: "St Mary’s, Scilly Isles" },
  { value: "St Matthew Island, Alaska", label: "St Matthew Island, Alaska" },
  { value: "St Paul Island, Alaska", label: "St Paul Island, Alaska" },
  { value: "St Peter Port", label: "St Peter Port" },
  { value: "St Petersburg, Russia", label: "St Petersburg, Russia" },
  { value: "St Pierre, Jersey", label: "St Pierre, Jersey" },
  { value: "St Tropez", label: "St Tropez" },
  { value: "St Vincent, Grenadines", label: "St Vincent, Grenadines" },
  { value: "St. Anthony, Newfoundland", label: "St. Anthony, Newfoundland" },
  { value: "St. Denis, Reunion", label: "St. Denis, Reunion" },
  { value: "St. Helena, Jamestown", label: "St. Helena, Jamestown" },
  { value: "St. Helena, UK", label: "St. Helena, UK" },
  { value: "St. Jean de Luz", label: "St. Jean de Luz" },
  { value: "St. Kilda, Outer Hebrides", label: "St. Kilda, Outer Hebrides" },
  { value: "St. Kitts", label: "St. Kitts" },
  { value: "St. Lucia", label: "St. Lucia" },
  { value: "St. Mary's, Isles of Scilly", label: "St. Mary's, Isles of Scilly" },
  { value: "St. Peter Port", label: "St. Peter Port" },
  { value: "St. Thomas (US Virgin Islands)", label: "St. Thomas (US Virgin Islands)" },
  { value: "St. Thomas, UK", label: "St. Thomas, UK" },
  { value: "St. Tropez", label: "St. Tropez" },
  { value: "Staffa", label: "Staffa" },
  { value: "Stanley Island", label: "Stanley Island" },
  { value: "Stanley, Falkland Islands", label: "Stanley, Falkland Islands" },
  { value: "Stanley,UK", label: "Stanley,UK" },
  { value: "Stavanger", label: "Stavanger" },
  { value: "Stewart Island", label: "Stewart Island" },
  { value: "Stockholm", label: "Stockholm" },
  { value: "Stornoway (Stornoway) - UK", label: "Stornoway (Stornoway) - UK" },
  { value: "Stornoway, Isle of Lewis", label: "Stornoway, Isle of Lewis" },
  { value: "Storstappen Island, Norway", label: "Storstappen Island, Norway" },
  { value: "Strasbourg", label: "Strasbourg" },
  { value: "Suez", label: "Suez" },
  { value: "Sullivan Bay, Santiago", label: "Sullivan Bay, Santiago" },
  { value: "Sumenep, Indonesia", label: "Sumenep, Indonesia" },
  { value: "Surabaya, Java", label: "Surabaya, Java" },
  { value: "Surtsey", label: "Surtsey" },
  { value: "Suva", label: "Suva" },
  { value: "Svalbard", label: "Svalbard" },
  { value: "Svartisen Glacier", label: "Svartisen Glacier" },
  { value: "Svolvaer", label: "Svolvaer" },
  { value: "Sydney, Australia", label: "Sydney, Australia" },
  { value: "Sydney, Canada", label: "Sydney, Canada" },
  { value: "Symi", label: "Symi" },
  { value: "Syracuse", label: "Syracuse" },
  { value: "Tadine", label: "Tadine" },
  { value: "Tadoussac", label: "Tadoussac" },
  { value: "Tagus Cove, Isabela Island", label: "Tagus Cove, Isabela Island" },
  { value: "Taiohae", label: "Taiohae" },
  { value: "Taipei", label: "Taipei" },
  { value: "Takamatsu", label: "Takamatsu" },
  { value: "Takoradi", label: "Takoradi" },
  { value: "Tallinn", label: "Tallinn" },
  { value: "Tamatave", label: "Tamatave" },
  { value: "Tami Island, PNG", label: "Tami Island, PNG" },
  { value: "Tampa", label: "Tampa" },
  { value: "Tangier", label: "Tangier" },
  { value: "Tanjung Wangi, Indonesia", label: "Tanjung Wangi, Indonesia" },
  { value: "Tanna Island", label: "Tanna Island" },
  { value: "Taolagnaro", label: "Taolagnaro" },
  { value: "Taormina", label: "Taormina" },
  { value: "Taranto", label: "Taranto" },
  { value: "Tarragona", label: "Tarragona" },
  { value: "Tasiilaq, Greenland", label: "Tasiilaq, Greenland" },
  { value: "Tauranga", label: "Tauranga" },
  { value: "Tempelfjord (Spitzbergen)", label: "Tempelfjord (Spitzbergen)" },
  { value: "Tenerife", label: "Tenerife" },
  { value: "Terneuzen", label: "Terneuzen" },
  { value: "Terre de Haut, Ile des Saintes", label: "Terre de Haut, Ile des Saintes" },
  { value: "Thera, Santorini", label: "Thera, Santorini" },
  { value: "Thessaloniki", label: "Thessaloniki" },
  { value: "Thira", label: "Thira" },
  { value: "Thira, Santorini", label: "Thira, Santorini" },
  { value: "Thorshaven", label: "Thorshaven" },
  { value: "Thursday Island", label: "Thursday Island" },
  { value: "Tianjin", label: "Tianjin" },
  { value: "Tilbury, England", label: "Tilbury, England" },
  { value: "Timaru", label: "Timaru" },
  { value: "Tingwon", label: "Tingwon" },
  { value: "Tobago", label: "Tobago" },
  { value: "Tobermory", label: "Tobermory" },
  { value: "Tokushima, Japan", label: "Tokushima, Japan" },
  { value: "Tokyo (Yokohama)", label: "Tokyo (Yokohama)" },
  { value: "Tokyo", label: "Tokyo" },
  { value: "Tolagnaro", label: "Tolagnaro" },
  { value: "Torishima", label: "Torishima" },
  { value: "Torngat Mountain National Park", label: "Torngat Mountain National Park" },
  { value: "Torquay", label: "Torquay" },
  { value: "Tortel", label: "Tortel" },
  { value: "Tortola", label: "Tortola" },
  { value: "Toulon", label: "Toulon" },
  { value: "Tournon", label: "Tournon" },
  { value: "Tournus", label: "Tournus" },
  { value: "Townsville", label: "Townsville" },
  { value: "Tracy Arm Fjord", label: "Tracy Arm Fjord" },
  { value: "Trapan (Sicily)", label: "Trapan (Sicily)" },
  { value: "Trapani", label: "Trapani" },
  { value: "Travemunde", label: "Travemunde" },
  { value: "Tresco, Isles of Scilly", label: "Tresco, Isles of Scilly" },
  { value: "Trevoux", label: "Trevoux" },
  { value: "Trier", label: "Trier" },
  { value: "Trieste", label: "Trieste" },
  { value: "Trincomalee", label: "Trincomalee" },
  { value: "Trincomalee, Sri Lanka", label: "Trincomalee, Sri Lanka" },
  { value: "Trinidad", label: "Trinidad" },
  { value: "Triple Island", label: "Triple Island" },
  { value: "Tristan da Cunha", label: "Tristan da Cunha" },
  { value: "Triton Bay", label: "Triton Bay" },
  { value: "Trogir", label: "Trogir" },
  { value: "Trois Ilets, Martinique", label: "Trois Ilets, Martinique" },
  { value: "Trois-Rivieres, Canada", label: "Trois-Rivieres, Canada" },
  { value: "Trollfjord", label: "Trollfjord" },
  { value: "Tromso", label: "Tromso" },
  { value: "Tromsoe", label: "Tromsoe" },
  { value: "Trondheim", label: "Trondheim" },
  { value: "Trujillo", label: "Trujillo" },
  { value: "Tsuruga, Japan", label: "Tsuruga, Japan" },
  { value: "Tuam Island", label: "Tuam Island" },
  { value: "Tulcea", label: "Tulcea" },
  { value: "Twillingate/Newfoundland", label: "Twillingate/Newfoundland" },
  { value: "Tyuleniy", label: "Tyuleniy" },
  { value: "Ubatuba", label: "Ubatuba" },
  { value: "Ujung Pandang", label: "Ujung Pandang" },
  { value: "Uligamu Island, Maldives", label: "Uligamu Island, Maldives" },
  { value: "Ullapool", label: "Ullapool" },
  { value: "Ulvik, Hardangerfjord", label: "Ulvik, Hardangerfjord" },
  { value: "Umbria (Ancona)", label: "Umbria (Ancona)" },
  { value: "Unga Spit, Alaska", label: "Unga Spit, Alaska" },
  { value: "Urvina Bay (Isabela)", label: "Urvina Bay (Isabela)" },
  { value: "Ushuaia", label: "Ushuaia" },
  { value: "Utupua Island, Solomons", label: "Utupua Island, Solomons" },
  { value: "Uummannaq", label: "Uummannaq" },
  { value: "Uunartoq Island, Greenland", label: "Uunartoq Island, Greenland" },
  { value: "Uyak Bay, Alaska, USA", label: "Uyak Bay, Alaska, USA" },
  { value: "Valencia", label: "Valencia" },
  { value: "Valparaiso", label: "Valparaiso" },
  { value: "Vancouver", label: "Vancouver" },
  { value: "Vanua Balavu", label: "Vanua Balavu" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vava 'u, Tonga Islands", label: "Vava 'u, Tonga Islands" },
  { value: "Vega Terron", label: "Vega Terron" },
  { value: "Venice", label: "Venice" },
  { value: "Vernon, France", label: "Vernon, France" },
  { value: "Vestmanna", label: "Vestmanna" },
  { value: "Vicente Roca", label: "Vicente Roca" },
  { value: "Victoria, British Colombia", label: "Victoria, British Colombia" },
  { value: "Victoria, Seychelles", label: "Victoria, Seychelles" },
  { value: "Vidin", label: "Vidin" },
  { value: "Vienna", label: "Vienna" },
  { value: "Vigo", label: "Vigo" },
  { value: "Vigur Island", label: "Vigur Island" },
  { value: "Vik", label: "Vik" },
  { value: "Vila", label: "Vila" },
  { value: "Villefranche (Nice)", label: "Villefranche (Nice)" },
  { value: "Vilshofen", label: "Vilshofen" },
  { value: "Virgin Gorda (BVI)", label: "Virgin Gorda (BVI)" },
  { value: "Vis", label: "Vis" },
  { value: "Visakhapatnam", label: "Visakhapatnam" },
  { value: "Visby, Sweden", label: "Visby, Sweden" },
  { value: "Vitoria", label: "Vitoria" },
  { value: "Viviers", label: "Viviers" },
  { value: "Vladivostok", label: "Vladivostok" },
  { value: "Vlissingen (Zeeland), Netherlands", label: "Vlissingen (Zeeland), Netherlands" },
  { value: "Vodice", label: "Vodice" },
  { value: "Volos", label: "Volos" },
  { value: "Vukovar", label: "Vukovar" },
  { value: "Wachau Valley", label: "Wachau Valley" },
  { value: "Waitangi, New Zealand", label: "Waitangi, New Zealand" },
  { value: "Walvis Bay", label: "Walvis Bay" },
  { value: "Warnemunde (For Berlin)", label: "Warnemunde (For Berlin)" },
  { value: "Warnemunde", label: "Warnemunde" },
  { value: "Waterford", label: "Waterford" },
  { value: "Wellington", label: "Wellington" },
  { value: "Wertheim, Austria", label: "Wertheim, Austria" },
  { value: "White Bay", label: "White Bay" },
  { value: "Whitsunday Islands", label: "Whitsunday Islands" },
  { value: "Whittier", label: "Whittier" },
  { value: "Willemstad, Curacao", label: "Willemstad, Curacao" },
  { value: "Willis Island", label: "Willis Island" },
  { value: "Wineglass bay", label: "Wineglass bay" },
  { value: "Wismar", label: "Wismar" },
  { value: "Wollongong, Australia", label: "Wollongong, Australia" },
  { value: "Woody Point, Newfoundland", label: "Woody Point, Newfoundland" },
  { value: "Worms", label: "Worms" },
  { value: "Wrangell, Alaska", label: "Wrangell, Alaska" },
  { value: "Wuerzburg", label: "Wuerzburg" },
  { value: "Wurzburg", label: "Wurzburg" },
  { value: "Wyndham, Western Australia", label: "Wyndham, Western Australia" },
  { value: "Xiamen", label: "Xiamen" },
  { value: "Xlendi, Gozo island", label: "Xlendi, Gozo island" },
  { value: "Yangon, Burma", label: "Yangon, Burma" },
  { value: "Yankitcha Island", label: "Yankitcha Island" },
  { value: "Yantai", label: "Yantai" },
  { value: "Yap Island, F.S.M", label: "Yap Island, F.S.M" },
  { value: "Yasawa Islands, Fiji Islands", label: "Yasawa Islands, Fiji Islands" },
  { value: "Yeosu", label: "Yeosu" },
  { value: "Yokohama", label: "Yokohama" },
  { value: "Yorkeys Knob", label: "Yorkeys Knob" },
  { value: "Yucatan", label: "Yucatan" },
  { value: "Zadar", label: "Zadar" },
  { value: "Zakinthos", label: "Zakinthos" },
  { value: "Zanzibar", label: "Zanzibar" },
  { value: "Zeebrugge", label: "Zeebrugge" },
  { value: "Zhujiajiao", label: "Zhujiajiao" },
  { value: "Zihuatanejo (Ixtapa)", label: "Zihuatanejo (Ixtapa)" }
];

export default visitPorts;