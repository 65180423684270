"use client"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './Navigation.module.css';
import { faCreditCardFront, faEnvelopeDot, faHeadset, faMagnifyingGlass } from '@awesome.me/kit-d4c82d9167/icons/classic/regular';
import Link from '../../globals/link';
import SmartCodeModal from '../../globals/navigation/SmartCodeModal';
import { useState } from 'react';

const MobileLinks = () => {
  const [smartCodeModalOpen, setSmartCodeModalOpen] = useState(false);

  return (
    <>
      <div className={styles.navigationMobileLinks}>
        <button className={styles.navigationMobileLink} onClick={() => setSmartCodeModalOpen(true)}>
          <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} />
          <span>Smart Code Search</span>
        </button>

        <SmartCodeModal isOpen={smartCodeModalOpen} onOpenChange={setSmartCodeModalOpen} />

        <Link className={styles.navigationMobileLink} href='https://www.haysitour.co.uk/fusion/viewmybooking.pl?site=www.cruisenation.com&vaction=login'>
          <FontAwesomeIcon icon={faCreditCardFront as IconProp} />
          <span>Make Payment</span>
        </Link>

        <Link className={styles.navigationMobileLink} href='/knowledge-base'>
          <FontAwesomeIcon icon={faHeadset as IconProp} />
          <span>Customer Support</span>
        </Link>

        <button className={styles.navigationMobileLink} onClick={() => {
          (document.getElementById("signup-modal") as HTMLDialogElement)?.showModal()
        }}>
          <FontAwesomeIcon icon={faEnvelopeDot as IconProp} />
          <span>Get Exclusive Offers</span>
        </button>
      </div>
    </>
  )
}

export default MobileLinks;