/**
 * Sorts the given array of objects by the given key, and then moves the
 * object with the given name to the beginning of the array. If the target
 * object is not found, the original array is returned with 'exists' set to
 * false.
 *
 * @param objectArray The array of objects to sort and prioritize.
 * @param key The key to sort the array by.
 * @param name The name of the object to prioritize.
 *
 * @returns An object with two properties: 'sortedArray', which is the sorted
 *          array with the target object at the beginning, and 'exists', which
 *          is a boolean indicating whether the target object was found.
 */
export const sortAndPrioritizeName = <T extends Record<string, any>>(
  objectArray: T[],
  key: string,
  name: string
): { 
  sortedArray: T[], 
  exists: boolean 
} => {
  // Validate input parameters
  if (!objectArray || objectArray.length === 0) {
    return { 
      sortedArray: objectArray, 
      exists: false 
    };
  }

  if (!key || !name) {
    throw new Error('Key and name must be provided');
  }

  // Find the index of the target item
  const targetIndex = objectArray.findIndex(item => 
    String(item[key]) === String(name)
  );

  // If the target is not found, return the original array with exists false
  if ((targetIndex === -1) || (targetIndex === 0)) {
    return { 
      sortedArray: objectArray, 
      exists: false 
    };
  }

  // Create a copy of the array and sort it
  const sortedArray = [...objectArray].sort((a, b) => {
    // Fallback to use the specified key for sorting if 'name' doesn't exist
    const sortKey = 'name' in a ? 'name' : key;
    return String(a[sortKey]).localeCompare(String(b[sortKey]));
  });

  // Remove the target item from its current position
  const targetItem = sortedArray.splice(targetIndex, 1)[0];

  // Insert the target item at the beginning of the array
  sortedArray.unshift(targetItem);

  return { 
    sortedArray, 
    exists: true 
  };
};


export const generate2DArray = (x: number, y: number, initialValue = null) => Array.from({ length: x }, () => Array.from({ length: y }, () => initialValue));

export const generate1DArray = (x: number, y: number, initialValue = null) => Array.from({ length: x * y }, () => initialValue);


/**
 * Creates a throttled version of the given function. The throttled function
 * will only invoke the given function at most once every `limit` milliseconds.
 *
 * @param func The function to throttle.
 * @param limit The maximum number of milliseconds to wait between invocations.
 *
 * @returns A throttled version of the given function.
 * 
 * @example const throttleAction = throttle(handleFunction, 500);
 */
export const throttle = <T extends (...args: any[]) => any>(
  func: T,
  limit: number
): ((...args: Parameters<T>) => void) => {
  let inThrottle: boolean = false;
  let lastFunc: ReturnType<typeof setTimeout> | undefined;
  let lastRan: number | undefined;

  return function(this: ThisParameterType<T>, ...args: Parameters<T>): void {
    if (!inThrottle) {
      func.apply(this, args);
      lastRan = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (lastRan && Date.now() - lastRan >= limit) {
          func.apply(this, args);
          lastRan = Date.now();
        }
      }, lastRan ? limit - (Date.now() - lastRan) : 0);
    }
  };
};