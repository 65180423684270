import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faStar as faStarRegular } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { faStar as faStarSolid } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import { faStarHalfStroke } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import styles from './StarRating.module.css';

type Props = {
  rating: number,
  totalStars: number
}

const StarRating = ({ rating, totalStars }: Props) => {
  return (
    <div className={styles.stars}>
      {
        Array.from({ length: totalStars }).map((_, key) => {
          const isFullStar = key < Math.floor(rating);
          const isHalfStar = !isFullStar && key === Math.floor(rating) && (rating % 1) >= 0.5;

          return (
            <FontAwesomeIcon
              key={key}
              icon={(isFullStar ? faStarSolid : isHalfStar ? faStarHalfStroke : faStarRegular) as IconProp}
              size="1x"
            />
          );
        })}
    </div>
  )
}

export default StarRating;