import { GroupResults, Holiday, Region, Ship } from '@/app/types/lambda-types'
import React, { use, useEffect, useState } from 'react'
import Pagination from '../pagination'
import GroupItem from './GroupItem'
import styles from './DynamicGroup.module.css'
import { getRegions, getShips } from '@/utils/queries'
import clsx from 'clsx'
import { generate1DArray } from '@/utils/actions'
import Select from '@/app/components/globals/select';

type Props = {
  group?: GroupResults | Holiday[]
  data_field: "" | "1" | "2"
  isLoading?: boolean
  onPageChange?: (page: number) => void
  onFiltersChange?: (filters: { [key: string]: string }) => void
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  show_pagination?: boolean
  uuid?: string
}

const DynamicGroup = ({ group, onPageChange, uuid, isLoading, show_pagination, data_field, onFiltersChange, setIsLoading }: Props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [ships, setShips] = useState<Ship[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);

  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchData = async () => {
      const responseShip = await getShips(uuid);
      const responseRegion = await getRegions();
      setShips(responseShip);
      setRegions(responseRegion);
    }
    fetchData();
  }, [])

  useEffect(() => {
    setPages((group as GroupResults)?.pages || 1);
    setPage(1);
  }, [(group as GroupResults)?.pages || 1]);

  useEffect(() => {
    onPageChange?.(page);
    setIsLoading?.(true);
  }, [page]);

  useEffect(() => {
    onFiltersChange?.(filters);
  }, [filters]);

  const getNextMonths = () => {
    const currentDate = new Date();
    const startMonth = currentDate.getMonth();
    const startYear = currentDate.getFullYear();
    const endYear = startYear + 3;

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const result = [];

    for (let year = startYear; year <= endYear; year++) {
      const start = year === startYear ? startMonth : 0;
      const end = year === endYear ? 11 : 11;
      for (let month = start; month <= end; month++) {
        result.push(`${months[month]} ${year}`);
      }
    }

    return result;
  }

  const parseDate = (date: string) => {
    const monthMap = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };
    const [month, year] = date.split(' ');

    if (!monthMap[month as keyof typeof monthMap] || isNaN(year as unknown as number)) {
      throw new Error(`Invalid month-year format: ${date}`);
    }

    // Return formatted date
    return `${year}-${monthMap[month as keyof typeof monthMap]}-01`;
  }

  return (
    <div className={styles.container}>
      {(data_field !== "2" && (show_pagination === undefined || show_pagination === true)) &&
        (
          <Pagination page={page} setPage={setPage} pages={pages} loading={isLoading} />
        )}
      {data_field !== "2" && (
        <div className={styles.filters}>
          <Select
            onChange={(value) => {
              setFilters({ ...filters, ship: value })
              setIsLoading?.(true);
            }}
            options={
              [
                {
                  value: "*",
                  label: "Show All Ship"
                },
                ...ships.map((ship) => (
                  { value: ship.value, label: ship.name }
                ))
              ]
            }
          />

          <Select
            onChange={(value) => {
              setFilters({ ...filters, region: value })
              setIsLoading?.(true);
            }}
            options={
              [
                {
                  value: "*",
                  label: "Show All Regions"
                },
                ...regions.map((region) => (
                  { value: region.value, label: region.name }
                ))
              ]
            }
          />

          <Select
            onChange={(value) => {
              setFilters({ ...filters, date: value })
              setIsLoading?.(true);
            }}
            options={
              [
                {
                  value: "*",
                  label: "Show All Dates"
                },
                ...getNextMonths().map((date) => (
                  { value: parseDate(date), label: date }
                ))
              ]
            }
          />
        </div>
      )}
      {isLoading === true ? (
        <div className={styles["grid-container"]}>
          {generate1DArray(3, 4).map((row, rowIndex) => (
            <div key={rowIndex} className={clsx(styles["grid-item"], styles["shimmer"])}></div>
          ))}
        </div>
      ) : (
        <div className={styles.holidays}>
          {data_field === "2" ? (
            <>
              {group && (Array.isArray(group)) && (group as Holiday[])?.map((holiday, index) => (
                <GroupItem holiday={holiday} key={index} />
                // <HolidayResult holiday={holiday} key={index} />
              ))}
            </>
          ) : (
            <>
              {(group as GroupResults)?.collection && (group as GroupResults).collection.map((holiday, index) => (
                <GroupItem holiday={holiday} key={index} />
              ))}
            </>
          )}
        </div>
      )}
      {(data_field !== "2" && (show_pagination !== false)) && (
        <Pagination page={page} setPage={setPage} pages={pages} loading={isLoading} />
      )}
    </div>
  )
}

export default DynamicGroup