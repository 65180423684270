import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import getOrdinal from '@/utils/data-manipulation/getOrdinal';
dayjs.extend(isSameOrBefore);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import styles from './SearchBar.module.css';

type DateOption = {
  label: string;
  value: string | null;
  fullDate?: string;
};

type DurationType = {
  label: string;
  value: string;
  selected: boolean;
};

type FlexibilityType = {
  label: string;
  value: number;
  selected: boolean;
};

interface DateDropdownProps {
  selectedDate: DateOption;
  onDateChange: (date: DateOption) => void;
  durations: DurationType[];
  flexibility: FlexibilityType[];
  selectedDuration: string;
  selectedFlexibility: number;
  onDurationChange: (duration: string) => void;
  onFlexibilityChange: (flexibility: number) => void;
}

const DateDropdown: React.FC<DateDropdownProps> = ({
  selectedDate,
  onDateChange,
  durations,
  flexibility,
  selectedDuration,
  selectedFlexibility,
  onDurationChange,
  onFlexibilityChange
}) => {
  const [byMonth, setByMonth] = useState(true);
  const [yearOffset, setYearOffset] = useState(0);
  const currentDate = dayjs();
  const minimumDate = currentDate.add(5, 'day').startOf('day');

  const months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
  ];

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getAvailableYears = () => {
    return Array.from({ length: 4 }, (_, i) => currentDate.year() + i);
  };

  const [selectedCalendarDate, setSelectedCalendarDate] = useState(() => {
    if (selectedDate.fullDate) {
      return dayjs(selectedDate.fullDate);
    }
    if (selectedDate.value) {
      const [month, year] = selectedDate.value.split('_');
      return dayjs(`${year}-${month}-01`);
    }
    return currentDate;
  });

  useEffect(() => {
    if (selectedDate.value) {
      const [month, year] = selectedDate.value.split('_');
      setSelectedCalendarDate(dayjs(`${year}-${month}-01`));
    }
    setByMonth(!selectedDate.fullDate);
  }, [selectedDate.value]);

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newDate = selectedCalendarDate.month(parseInt(event.target.value) - 1);
    setSelectedCalendarDate(newDate);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newDate = selectedCalendarDate.year(parseInt(event.target.value));
    setSelectedCalendarDate(newDate);
  };

  const handlePrevMonth = () => {
    setSelectedCalendarDate(prev => prev.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedCalendarDate(prev => prev.add(1, 'month'));
  };

  const getCalendarDays = (date: dayjs.Dayjs) => {
    const startOfMonth = date.startOf('month');
    const endOfMonth = date.endOf('month');
    const startDay = startOfMonth.day();
    const daysInMonth = endOfMonth.date();

    const days: Array<{
      date: dayjs.Dayjs | null;
      disabled: boolean;
      flexibilityDiff?: number;
      isFlexible?: boolean;
    }> = [];

    // Add empty cells for days before the start of the month
    for (let i = 0; i < startDay; i++) {
      days.push({ date: null, disabled: true });
    }

    // Add the days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDay = startOfMonth.date(i);
      const disabled = currentDay.isBefore(minimumDate);

      let flexibilityDiff: number | undefined;
      let isFlexible = false;

      if (selectedDate.fullDate && selectedFlexibility > -1) {
        const selectedDayJs = dayjs(selectedDate.fullDate);
        const diffDays = currentDay.diff(selectedDayJs, 'day');
        if (Math.abs(diffDays) <= selectedFlexibility) {
          flexibilityDiff = diffDays;
          isFlexible = true;
        }
      }

      days.push({
        date: currentDay,
        disabled,
        flexibilityDiff,
        isFlexible
      });
    }

    // Add empty cells to complete the last week
    const remainingCells = (7 - (days.length % 7)) % 7;
    for (let i = 0; i < remainingCells; i++) {
      days.push({ date: null, disabled: true });
    }

    return days;
  };

  const handleDaySelect = (day: dayjs.Dayjs) => {
    const monthValue = day.format('MM');
    const year = day.year();
    const monthYear = `${monthValue}_${year}`;
    const dayOfMonth = day.date();

    onDateChange({
      label: `${getOrdinal(dayOfMonth)} ${day.format('MMM YYYY')}`,
      value: monthYear,
      fullDate: day.format('YYYY-MM-DD')
    });
  };

  const handleViewChange = (isMonthView: boolean) => {
    setByMonth(isMonthView);
    if (isMonthView && selectedDate.fullDate) {
      const [month, year] = selectedDate.value!.split('_');
      onDateChange({
        label: `${months.find(m => m.value === month)?.label} ${year}`,
        value: selectedDate.value,
        fullDate: undefined
      });
    }
  };

  const years = getAvailableYears();
  const isFirstMonth = selectedCalendarDate.startOf('month').isSameOrBefore(currentDate.startOf('month'));

  return (
    <div className={styles.dropdownDate}>
      <div className={styles.dropdownDateToggleHeader}>
        <div></div>
        <div className={`${styles.toggleSwitch} ${byMonth ? styles.toggleSwitchOff : styles.toggleSwitchOn}`}>
          <button
            className={`${styles.toggleButton} ${byMonth ? styles.toggleButtonActive : ''}`}
            onClick={() => handleViewChange(true)}
          >
            I am flexible
          </button>
          <button
            className={`${styles.toggleButton} ${!byMonth ? styles.toggleButtonActive : ''}`}
            onClick={() => handleViewChange(false)}
          >
            Set specific day
          </button>
        </div>
        <div></div>
      </div>

      {byMonth && (
        <>
          <div className={styles.yearChangeButtons}>
            <button
              className={styles.yearChange}
              onClick={() => setYearOffset(prev => prev - 1)}
              disabled={yearOffset === 0}
            >
              <FontAwesomeIcon icon={faChevronLeft as IconProp} />
            </button>
            <button
              className={styles.yearChange}
              onClick={() => setYearOffset(prev => prev + 1)}
              disabled={yearOffset >= 1}
            >
              <FontAwesomeIcon icon={faChevronRight as IconProp} />
            </button>
          </div>

          <div className={styles.years}>
            {years.slice(0 + yearOffset * 2, 2 + yearOffset * 2).map((year) => (
              <div key={year} className={styles.year}>
                <h3>{year}</h3>
                <div className={styles.months}>
                  {months.map((month) => {
                    const monthValue = `${month.value}_${year}`;
                    const monthDate = dayjs(`${year}-${month.value}-01`);
                    const isDisabled = monthDate.isBefore(currentDate.startOf('month'));

                    return (
                      <button
                        key={monthValue}
                        className={`${styles.month} ${selectedDate.value === monthValue ? styles.monthSelected : ''}`}
                        disabled={isDisabled}
                        onClick={() => onDateChange({
                          label: `${month.label} ${year}`,
                          value: monthValue
                        })}
                      >
                        {month.label.slice(0, 3)}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {!byMonth && (
        <div className={styles.dropdownDateDayCalendar}>
          <div className={styles.calendarHeader}>
            <button
              className={styles.yearChange}
              onClick={handlePrevMonth}
              disabled={isFirstMonth}
            >
              <FontAwesomeIcon icon={faChevronLeft as IconProp} />
            </button>

            <div className={styles.dateSelectors}>
              <select
                value={selectedCalendarDate.format('MM')}
                onChange={handleMonthChange}
                className={styles.monthSelect}
              >
                {months.map((month, index) => (
                  <option
                    key={index}
                    value={month.value}
                    disabled={currentDate.year() === parseInt(selectedCalendarDate.format('YYYY')) &&
                      parseInt(month.value) < parseInt(currentDate.format('MM'))}
                  >
                    {month.label}
                  </option>
                ))}
              </select>

              <select
                value={selectedCalendarDate.format('YYYY')}
                onChange={handleYearChange}
                className={styles.yearSelect}
              >
                {years.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={styles.yearChange}
              onClick={handleNextMonth}
            >
              <FontAwesomeIcon icon={faChevronRight as IconProp} />
            </button>
          </div>

          <div className={styles.calendarGrid}>
            <div className={styles.weekDays}>
              {weekDays.map((day, index) => (
                <div key={index} className={styles.weekDay}>{day}</div>
              ))}
            </div>

            <div className={styles.calendarDays}>
              {getCalendarDays(selectedCalendarDate).map((day, index) => (
                <button
                  key={index}
                  className={`${styles.calendarDay} 
                    ${!day.date ? styles.emptyDay : ''} 
                    ${day.date && selectedDate.fullDate === day.date.format('YYYY-MM-DD') ? styles.selectedDay : ''}
                    ${!day.disabled && day.isFlexible ? styles.flexibleDay : ''}`}
                  disabled={!day.date || day.disabled}
                  onClick={() => day.date && handleDaySelect(day.date)}
                >
                  {day.date?.date()}
                  {!day.disabled && day.flexibilityDiff !== undefined && day.flexibilityDiff !== 0 && (
                    <span className={styles.flexibilityIndicator}>
                      {day.flexibilityDiff > 0 ? '+ ' : '- '}{Math.abs(day.flexibilityDiff)}{' d'}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className={styles.advancedSearchInputGroups}>
        <div className={styles.advancedSearchInputGroup}>
          <div className={styles.duration}>
            Duration
            <select
              value={selectedDuration}
              onChange={(e) => onDurationChange(e.target.value)}
            >
              {durations.map((duration, index) => (
                <option
                  key={index}
                  value={duration.value}
                >
                  {duration.label}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.flexibility}>
            Flexibility
            <select
              value={selectedFlexibility}
              onChange={(e) => onFlexibilityChange(parseInt(e.target.value))}
            >
              {flexibility.map((option, index) => (
                <option
                  key={index}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateDropdown;