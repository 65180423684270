import { ShipOverviewStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React, { useEffect, useState } from 'react'
import { ShipDetail } from '@/app/types/lambda-types'
import { getShipDetailsByTTID } from '@/utils/queries';
import styles from './ShipOverview.module.css'
import Container from '../../globals/container';
import PageSection from '../../globals/page_section';
import { Overview } from '../../../types/lambda-types';
import parse from 'html-react-parser';
import { EmblaOptionsType } from "embla-carousel";

const OPTIONS: EmblaOptionsType = {
  loop: true,
  containScroll: 'trimSnaps',
}

const ShimmerLoading = () => {
  return (
    <div className={styles.shimmerWrapper}>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
      <div className={styles.shimmerLine}></div>
    </div>
  );
};

const ShipOverview = ({ blok }: { blok: ShipOverviewStoryblok }) => {
  const [shipDetail, setShipDetail] = useState<ShipDetail | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchShipDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getShipDetailsByTTID(parseInt(blok.ship || '0'));
      setShipDetail(response);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchShipDetails();
  }, [blok.ship])

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h1 className={styles.title}>Your floating resort</h1>
          {isLoading ? (
            <ShimmerLoading />
          ) : (
            shipDetail?.overview.map((overview: Overview) => (
              <div key={overview.id}>
                {overview.description.map((description: string) => (
                  <p key={description}>{parse(description)}</p>
                ))}
              </div>
            ))
          )}
        </Container>
      </PageSection>
    </div>
  )
}

export default ShipOverview