import React from "react";
import { storyblokEditable, StoryblokComponent, SbBlokData } from "@storyblok/react";
import type { FullWidthImageStoryblok } from "@/app/types/component-types-sb";
import Image from '@/app/components/globals/image';
import styles from './FullWidthImage.module.css';
import { motion } from 'framer-motion';

const FullWidthImage = ({ blok }: { blok: FullWidthImageStoryblok }) => {
  return (
    <div className="page-section" {...storyblokEditable(blok)}>
      {blok.title ?
        <motion.h2 className="section-header"
          initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          {blok.title}
        </motion.h2> : null
      }
      <motion.div
        initial={{ opacity: 0, clipPath: "inset(100% 0 0 0)" }}
        whileInView={{ opacity: 1, clipPath: "inset(0% 0 0 0)" }}
        viewport={{ once: true, margin: "100px" }}
        transition={{ duration: 1 }}
        className={styles.imageWrapper}>
        {blok.image ?
          <Image asset={blok.image} /> : null
        }
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
      >
        {
          blok.content?.map((nestedBlok: SbBlokData) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))
        }
      </motion.div>
    </div>
  )
}

export default FullWidthImage;