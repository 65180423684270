import { TabbedFeaturedDealStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React from 'react'
import Group from '../../group';

const TabbedFeaturedDeal = ({ blok, currentTab }: { blok: TabbedFeaturedDealStoryblok, currentTab?: number }) => {

    return (
        <div {...storyblokEditable(blok)}>
            <Group blok={blok.group[0]} currentTab={currentTab} />
        </div>
    )
}

export default TabbedFeaturedDeal