import styles from './Select.module.css';
interface Props {
  className?: string;
  options?: Array<{ label: string, value: string | number }>; // Added number to support your ID type
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: string;
}

const Select = ({
  className,
  options,
  value,
  onChange,
  disabled,
  error
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <select
        className={styles.select}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        disabled={disabled}
      >
        {
          options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          )) || null
        }
      </select>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  )
}

export default Select;