import { InformationTimes } from "@/app/components/globals/navigation/Navigation";
import { FeefoMerchantResponse, FeefoReviewsResponse } from "@/app/types/feefo-types";
import { getCookie, setCookie } from "./cookies";
import { CruiseShip, GroupResults, Holiday, KnowledgeBaseArticleViewType, KnowledgeBaseChildrenRoutes, KnowledgeBaseHeaderSearchFilter, PortGuide, Region, RegionWeather, Ship, ShipDetail, StoryblokState, WhatsIncludedType } from "@/app/types/lambda-types";
import axios from "axios";

/**
 * Fetch a holiday from the /api/dynamic-group/star-buy endpoint.
 *
 * @param {string} uuid The UUID of the group to fetch.
 * @returns {Promise<{holiday?: Holiday, status: boolean}>} A promise that resolves to an object
 *          with a `holiday` property containing the fetched holiday data, if any, and a
 *          `status` property indicating the success of the request.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getStarBuyHoliday = async (uuid: string): Promise<{
	holiday?: Holiday
	status: boolean
}> => {
	try {
		const response = await axios.get<{
			holiday?: Holiday
			status: boolean
		}>(`/api/dynamic-group/star-buy/${JSON.stringify({ uuid })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch starbuy data");
	}
}

export const getBlogPosts = async (per_page?: number, page?: number): Promise<WordPressPost[]> => {
	try {
		const response = await axios.get<WordPressPost[]>('/api/blog-posts/',
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch blog posts");
	}
}

/**
 * Fetch a list of holidays from the /api/dynamic-group endpoint.
 *
 * @param {string} uuid The UUID of the group to fetch.
 * @param {string | number} pageSize The number of results to return per page.
 * @param {string} [sortBy] The field to sort the results by.
 * @param {string} [sortDirection] The direction to sort the results in.
 * @param {boolean} [withImages] Whether to include images in the results.
 * @param {number} [page] The page number to fetch.
 * @returns {Promise<GroupResults>} A promise that resolves to an object containing the
 *          fetched holiday data, including the page number, number of pages, and
 *          total number of results.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getDynamicGroups = async (
	uuid: string,
	pageSize: string | number,
	sortBy?: string,
	sortDirection?: string,
	withImages?: boolean,
	page?: number,
	ship?: any,
	region?: any,
	date?: any
): Promise<GroupResults> => {
	try {
		const response = await axios.get<GroupResults>(`/api/dynamic-group/${JSON.stringify({
			uuid, pageSize, sortBy, sortDirection, withImages, page, ship, region, date
		})}`, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch dynamic groups data");
	}
}



export const getFeaturedDeals = async (
	uuid: string
): Promise<GroupResults> => {
	try {
		const response = await axios.get<GroupResults>(`/api/featured-deals/${JSON.stringify({ uuid })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch featured deals data");
	}
}

/**
 * Fetch a list of cruise ships from the /api/ships/by_line_id endpoint.
 *
 * @param {string} id The ID of the cruise line to fetch.
 * @returns {Promise<CruiseShip[]>} A promise that resolves to an array of cruise ship
 *          objects fetched from the API.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getCruiseShipsByLineId = async (id: string): Promise<CruiseShip[]> => {
	try {
		const response = await axios.get<CruiseShip[]>(`/api/ships/by_line_id/${id}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch cruiseship by line id data");
	}
}


/**
 * Fetch weather data for a region from the /api/weather_chart endpoint.
 *
 * @param {string} region_id The ID of the region to fetch weather data for.
 * @param {string} [port_guide_id] The ID of the port guide to fetch weather data for.
 * @returns {Promise<RegionWeather>} A promise that resolves to an object containing the
 *          weather data for the given region and port guide.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getWeatherChartData = async (region_id?: string, sub_region_id?: string, port_guide_id?: string): Promise<RegionWeather> => {
	try {
		const response = await axios.get<RegionWeather>(`/api/weather_chart/${JSON.stringify({ region_id, sub_region_id, port_guide_id })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch weather data");
	}
}



/**
 * Fetch map coordinates for a region from the /api/get-map-coordinates/storyblok endpoint.
 *
 * @param {string} region_id The ID of the region to fetch map coordinates for.
 * @param {string} [sub_region_id] The ID of the sub-region to fetch map coordinates for.
 * @returns {Promise<PortGuide[]>} A promise that resolves to an array of port guide
 *          objects containing the map coordinates for the given region.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getRegionMapCoordinates = async ({ region_id, sub_region_id }: { region_id?: string, sub_region_id?: string }): Promise<PortGuide[]> => {
	try {
		const response = await axios.get<PortGuide[]>(`/api/get-map-coordinates/storyblok/${JSON.stringify({ region_id, sub_region_id })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch map data");
	}
}



/**
 * Searches the knowledge base for articles and categories matching the given query.
 *
 * If the query is empty, returns an empty result set.
 *
 * @param {string} query The search query to execute.
 * @returns {Promise<KnowledgeBaseHeaderSearchFilter>} A promise that resolves to an object
 *          containing the search results, or rejects with an error if the request fails.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const knowledgeBaseSearchFilter = async (query: string): Promise<KnowledgeBaseHeaderSearchFilter> => {
	if (query.trim().length === 0) return { articles: [], categories: [], total_articles: 0, total_categories: 0 };
	try {
		const response = await axios.get<KnowledgeBaseHeaderSearchFilter>(`/api/knowledge-base/search-filter/${JSON.stringify({ query })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to search knowledge base");
	}
}


/**
 * Fetch the children routes for a knowledge base category from the /api/knowledge-base/children-routes endpoint.
 *
 * @param {string} uuid The UUID of the category to fetch children routes for.
 * @returns {Promise<KnowledgeBaseChildrenRoutes>} A promise that resolves to an object containing the children routes
 *          for the given category, or rejects with an error if the request fails.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getKnowledgeBaseChildrenRoutes = async (uuid: string): Promise<KnowledgeBaseChildrenRoutes> => {
	try {
		const response = await axios.get<KnowledgeBaseChildrenRoutes>(`/api/knowledge-base/children-routes/${JSON.stringify({ uuid })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch knowledge base children routes");
	}
}

/**
 * Fetch the article view for a knowledge base article from the /api/knowledge-base/article-view endpoint.
 *
 * @param {string} uuid The UUID of the article to fetch the article view for.
 * @returns {Promise<KnowledgeBaseArticleViewType>} A promise that resolves to an object containing
 *          the article view for the given article, or rejects with an error if the request fails.
 * @throws {Error} If the request fails or the response is invalid.
 */

export const getKnowledgeBaseArticleView = async (uuid: string): Promise<KnowledgeBaseArticleViewType> => {
	try {
		const response = await axios.get<KnowledgeBaseArticleViewType>(`/api/knowledge-base/article-view/${JSON.stringify({ uuid })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch knowledge base article view");
	}
}



/**
 * Fetch the "whats included" data for a given cruise line and flight/hotel status
 * from the /api/whats-included endpoint.
 *
 * @param {Object} props An object containing the cruise line ID and optional
 *        flags for whether the cruise line has flights and/or hotels.
 * @param {number} props.cruise_line_tt_id The ID of the cruise line to fetch data for.
 * @param {0|1} [props.has_flights] Whether the cruise line has flights.
 * @param {0|1} [props.has_hotels] Whether the cruise line has hotels.
 * @returns {Promise<WhatsIncludedType[]>} A promise that resolves to an array of
 *          "whats included" items for the given cruise line and flight/hotel status.
 * @throws {Error} If the request fails or the response is invalid.
 */
export const getWhatsIncluded = async (props: {
	cruise_line_tt_id: number,
	has_flights?: 0 | 1,
	has_hotels?: 0 | 1,
}): Promise<WhatsIncludedType[]> => {
	try {
		const response = await axios.get<WhatsIncludedType[]>(`/api/whats-included/${JSON.stringify(props)}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch whats included");
	}
}


/**
 * Fetch ship details for a given TTID from the /api/ship-details-by-ttid endpoint.
 *
 * @param {number} tt_id The TTID of the ship to fetch details for.
 * @returns {Promise<ShipDetail>} A promise that resolves to an object containing the
 *          ship details for the given TTID.
 * @throws {Error} If the request fails or the response is invalid.
 */

export const getShipDetailsByTTID = async (tt_id: number): Promise<ShipDetail> => {
	try {
		const response = await axios.get<ShipDetail>(`/api/ship-details-by-ttid/${JSON.stringify({ tt_id })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch ship details by ttid");
	}
}


/**
 * Fetch the opening times from the /api/opening-times endpoint.
 *
 * @returns {Promise<InformationTimes>} A promise that resolves to an object containing
 *          the opening times information.
 * @throws {Error} If the request fails or the response is invalid.
 */

export const getOpeningTimes = async (): Promise<InformationTimes> => {
	try {
		const response = await axios.get<InformationTimes>('/api/opening-times',
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch opening times");
	}
}


/**
 * Checks the current state of the Storyblok CDN.
 *
 * This function returns a promise that resolves to an object with the following
 * properties:
 *
 * - `data`: An object with the current state of the Storyblok CDN, which has the
 *           following properties:
 *   - `status`: A string indicating the current status of the Storyblok CDN.
 *   - `message`: A string indicating whether the Storyblok CDN is currently published
 *                or not.
 * - `cacheTime`: An optional number that indicates the current cache time of the
 *               Storyblok CDN. This property is only present if the Storyblok CDN
 *               is currently published.
 *
 * @returns {Promise<{data: StoryblokState, cacheTime: number}>}
 * @throws {Error} If the request fails or the response is invalid.
 */
export const checkStoryblokState = async (baseUrl?: string, accessToken?: string, pathname?: string): Promise<{
	data: StoryblokState,
	cacheTime?: number
}> => {

	try {
		const response = await axios.get<StoryblokState>(`${baseUrl ? baseUrl : process.env.NEXT_PUBLIC_BASE_URL}/api/storyblok-state/${JSON.stringify({ pathname })}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);

		if (response.data.message === 'true') {
			const cacheResponse = await axios.get(`https://api.storyblok.com/v2/cdn/spaces/me/?token=${accessToken ? accessToken : process.env.NEXT_PUBLIC_STORYBLOK_KEY}`, {
				headers: {
					'Content-Type': 'application/json'
				}
			});

			return {
				data: response.data,
				cacheTime: cacheResponse.data.space.version
			}
		}

		return {
			data: response.data,
			cacheTime: undefined
		};
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch storyblok state");
	}
}


export const getShips = async (uuid?: string): Promise<Ship[]> => {
	try {
		const response = await axios.get<Ship[]>(`/api/ships/${JSON.stringify({ uuid })}`, {
			headers: {
				'Content-Type': 'application/json'
			}
		}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch ships");
	}
}


export const getRegions = async (): Promise<Region[]> => {
	try {
		const response = await axios.get<Region[]>('/api/regions',
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch regions");
	}
}

/**
 * Retrieves the phone number associated with the given affiliate code, and stores aff code in cookies for future reference.
 *
 * @param {string | null | undefined} aff - The affiliate code to retrieve the phone number for.
 * @returns {Promise<string>} - A promise that resolves to the phone number associated with the given affiliate code.
 * @throws {Error} - If the request fails or the response is invalid, an error is thrown with a descriptive message.
 */
export const getPhoneNumber = async (aff: string | null | undefined): Promise<string> => {
	try {
		let affCode = aff;

		if (aff && typeof aff === 'string') {
			const expiryDate = new Date();
			expiryDate.setDate(expiryDate.getDate() + 30); // 30 days
			setCookie('aff', aff, { expires: expiryDate });
			affCode = encodeURIComponent(aff);
		}
		else {
			affCode = getCookie('aff');
		}

		const response = await axios.get<string>(`/api/aff-to-phone${affCode ? '?aff=' + affCode : ''}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return response.data;
	} catch (e) {
		console.error(e);
		throw new Error("Failed to fetch phone number");
	}
}
