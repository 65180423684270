import { Promotion } from "@/app/types/lambda-types"

import styles from './Promotions.module.css';
import DynamicIcon from "../dynamic-icon";
import { AlertDialog, AlertDialogContent, AlertDialogTitle, AlertDialogTrigger } from "../alert-dialog/AlertDialog";

const Promotions = ({ promotions }: { promotions: Promotion[] | null | undefined }) => {
  return (
    <>
      {promotions ? promotions.map((promotion, key) => (
        <AlertDialog key={key}>
          <AlertDialogTrigger>
            <div className={styles.promotion}>
              {<DynamicIcon iconName={promotion.icon} style="regular" />}
              {promotion.summary}
            </div>
          </AlertDialogTrigger>
          <AlertDialogContent className={styles.alertDialogContent}>
            <AlertDialogTitle className={styles.alertDialogTitle}>{promotion.title}</AlertDialogTitle>

            <p key={key} className={styles.alertDialogSubitle}>{promotion.short_description}</p>

            <div className={styles.alertDialogDescriptionWrapper}>
              {promotion.description.map((promotionDescription, key) => (
                <p key={key} className={styles.alertDialogDescription}>{promotionDescription}</p>
              ))}
            </div>

            {(promotion.asterisk && promotion.asterisk.length > 0) && (
              <p className={styles.alertDialogAsterisk}><span>NOTE:</span> {promotion.asterisk}</p>
            )}
          </AlertDialogContent>
        </AlertDialog>
      )) : null}
    </>
  )
}

export default Promotions;