import React, { FC, HTMLAttributes, ReactElement } from 'react'
import { AssetStoryblok } from '@/app/types/component-types-sb';
import { ReactSVG } from 'react-svg';

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>

type Range<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

type T = Range<1, 100>

type SourceSetEntry = {
    size: {
        width: number
        height: number
    }
    crop: boolean
}

export type ImageProps = {
    asset: AssetStoryblok | string
    className?: string
    crop?: boolean
    lazyLoad?: boolean
    quality?: T
    isSVG?: boolean
    useSource?: boolean
    sourceSet?: Record<string, SourceSetEntry> // Keys will be width numbers as strings
    size?: {
        width: number
        height: number
    }
    alt?: string
    style?: React.CSSProperties
    objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down'
}

/**
 * A custom image component to handle responsive images, image cropping, and lazy loading.
 * 
 * @param {ImageProps} props - The component props.
 * @param {AssetStoryblok | string} props.asset - The image asset to use.
 * @param {{ width: number; height: number }} [props.size] - Optional custom dimensions for the image.
 * @param {boolean} [props.crop] - Whether to apply crop filters. Defaults to true.
 * @param {boolean} [props.lazyLoad] - Whether to enable lazy loading. Defaults to true.
 * @param {number} [props.quality] - The image quality to use.
 * @param {boolean} [props.isSVG] - Whether the image is an SVG. Defaults to false.
 * @param {boolean} [props.useSource] - Whether to use the source tag for responsive images. Defaults to true.
 * @param {Record<string, SourceSetEntry>} [props.sourceSet] - A configuration object for responsive images.
 * @param {string} [props.alt] - The alt text for the image.
 * @param {string} [props.objectFit] - The object fit for the image. Defaults to 'cover'.
 * @example <Image
             lazyLoad={false}
             asset={blok.background_image}
             useSource={false}
             quality={70}
             size={{
               width: 1920,
               height: 1080
             }}
             sourceSet={{
               '768': {  // Add tablet breakpoint
                 size: {
                   width: 820,
                   height: 1180
                 },
                 crop: true
               },
               '1024': {  // Desktop size
                 size: {
                   width: 1920,
                   height: 1080
                 },
                 crop: false
               }
             }}
           /> 
   @example <Image
               lazyLoad={false}
               asset={blok.background_image}
               quality={70}
               size={{
                 width: 1920,
                 height: 1080
               }}/>         
 * @param {React.CSSProperties} [props.style] - The custom CSS styles for the image.
 * @returns {ReactElement} The rendered image component.
 */
const Image: FC<ImageProps & HTMLAttributes<HTMLImageElement>> = ({
    asset,
    size,
    crop = false,
    className,
    lazyLoad = true,
    style,
    isSVG = false,
    quality,
    sourceSet,
    alt,
    useSource = true,
    objectFit = 'cover',
    ...props
}: ImageProps): ReactElement => {
    if (!asset) return (<></>);

    const fileType = (filename: string) => filename?.split('.').pop();

    const getQuality = (): string => (quality ? `:quality(${quality.toString()})` : '');

/**
 * Generates a URL for an image asset without applying crop filters.
 * 
 * @param {Object} [customSize] - Optional custom dimensions for the image.
 * @param {number} customSize.width - The width of the image.
 * @param {number} customSize.height - The height of the image.
 * @returns {string} The generated URL for the image asset with specified size and format.
 */

    /**
     * Generates a URL for an image asset without applying crop filters.
     * 
     * @param {Object} [customSize] - Optional custom dimensions for the image.
     * @param {number} customSize.width - The width of the image.
     * @param {number} customSize.height - The height of the image.
     * @returns {string} The generated URL for the image asset with specified size and format.
     */
    const generateUrlWithoutCrop = (customSize?: { width: number; height: number }) => {
        const focusAsset = asset as AssetStoryblok;
        if (!focusAsset?.filename) return '';

        const sizeToUse = customSize || size;
        const sizePath = sizeToUse ? `/${sizeToUse.width}x${sizeToUse.height}` : '';
        const isNotSvg = fileType(focusAsset.filename) !== 'svg';

        return `${focusAsset.filename}${isNotSvg ? `/m${sizePath}/filters:format(webp)${getQuality()}` :
            sizePath !== '' ? `/m${sizePath}` : ''}`;
    }

    /**
     * Generates a URL for an image asset, applying crop filters if specified.
     * 
     * @param {boolean} [crop] - Whether to apply crop filters. Defaults to true.
     * @param {Object} [customSize] - Optional custom dimensions for the image.
     * @param {number} customSize.width - The width of the image.
     * @param {number} customSize.height - The height of the image.
     * @returns {string} The generated URL for the image asset with specified size, format, and crop filters.
     */
    const generateUrl = (crop?: boolean, customSize?: { width: number; height: number }) => {
        const focusAsset = asset as AssetStoryblok;
        if (!focusAsset?.filename) return '';
        if (!crop) return generateUrlWithoutCrop(customSize);

        const sizeToUse = customSize || size;
        if (!sizeToUse) return `${focusAsset.filename}${fileType(focusAsset.filename) !== 'svg' ? `/m/filters:format(webp)${getQuality()}` : ''}`;

        let focusParams = '';
        if (focusAsset.focus) {
            const [leftTop, rightBottom] = focusAsset.focus.split(':');
            const [left, top] = leftTop.split('x').map(Number);
            const [right, bottom] = rightBottom.split('x').map(Number);

            const newFocus = [
                Math.ceil(left - (sizeToUse.width / 100)),
                Math.ceil(top - (sizeToUse.height / 100)),
                Math.ceil(right + (sizeToUse.width / 100)),
                Math.ceil(bottom + (sizeToUse.height / 100))
            ];

            focusParams = `:focal(${newFocus[0]}x${newFocus[1]}:${newFocus[2]}x${newFocus[3]})`;
        }

        return `${focusAsset.filename}/m/${sizeToUse.width}x${sizeToUse.height}${fileType(focusAsset.filename) !== 'svg' ? `/filters:format(webp)${focusParams}${getQuality()}` : ''
            }`;
    }

/**
 * Generates a source set string for responsive images based on the provided source set configuration.
 * 
 * @returns {string} A string containing URLs for different image sizes, formatted as a source set.
 * If the source set is not defined or not an object, it returns an empty string.
 */

    const generateSrcSet = () => {
        if (!sourceSet || typeof sourceSet !== 'object') return '';

        return Object.entries(sourceSet)
            .map(([width, value]) => {
                const url = value.crop ? generateUrl(value.crop, value.size) : generateUrlWithoutCrop(value.size);
                return `${url}`;
            })
    }

    /**
     * Generates a string containing media queries for responsive image sizes based on the provided source set configuration.
     * 
     * @returns {string} A string containing media queries for different image sizes, formatted as sizes attribute.
     * If the source set is not defined or not an object, it returns '100vw'.
     */
    const generateSizes = () => {
        if (!sourceSet || typeof sourceSet !== 'object') return '100vw';

        const breakpoints = Object.keys(sourceSet)

        return breakpoints
            .map((bp, index) => {
                if (index === breakpoints.length - 1) {
                    return `(max-width: ${bp}px)`;
                }
                return `(max-width: ${bp}px)`;
            })
    }

    const commonStyles = {
        ...style,
        objectFit,
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        color: 'transparent'
    } as const;

    if (isSVG) {
        return (
            <div className="absolute w-full h-full" style={{ top: 0, left: 0 }}>
                <ReactSVG
                    src={(asset as AssetStoryblok).filename as string}
                    className={className}
                    style={commonStyles}
                    {...props}
                />
            </div>
        );
    }

    if (!useSource) {
        const imageSizes = generateSizes();
        const imageSrcSets = generateSrcSet();
        return (
            <picture>
                {(imageSrcSets as string[]).map((src, index) => (
                    <source key={index} media={imageSizes[index]} srcSet={src} />
                ))}
                <img
                    className={className}
                    style={commonStyles}
                    loading={lazyLoad ? 'lazy' : 'eager'}
                    src={useSource ? (typeof asset === 'string' ? asset : generateUrl(crop, size)) : generateUrl(crop, size)}
                    alt={typeof asset === 'string' ? (alt ?? '') : (asset.alt ?? '')}
                    {...props}
                />
            </picture>
        )
    }

    return (
        <div className="absolute w-full h-full" style={{ top: 0, left: 0 }}>
            <img
                className={className}
                style={commonStyles}
                loading={lazyLoad ? 'lazy' : 'eager'}
                src={useSource ? (typeof asset === 'string' ? asset : generateUrl(crop, size)) : generateUrl(crop, size)}
                alt={typeof asset === 'string' ? (alt ?? '') : (asset.alt ?? '')}
                {...props}
            />
        </div>
    );
};

export default Image;