import React from "react";
import { CategoryStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from "./Categories.module.css";
import Image from "@/app/components/globals/image";
import Link from "next/link";

const Category = ({ blok }: { blok: CategoryStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <Link href={'/knowledge-base/category/3521a9e5-b385-4158-9721-5171d5222298'} className={styles.category}>
        <div className={styles.category_icon}>
          <Image
            asset={blok.icon || ""}
            alt={blok.icon_alt}
            size={{ width: 50, height: 50 }}
          />
        </div>
        <h4>{blok.title}</h4>
      </Link>
    </div>
  );
};

export default Category;
