import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './WhyCruiseNation.module.css';
import { faIslandTreePalm, faSmile, faTrophy } from '@awesome.me/kit-d4c82d9167/icons/classic/thin';
import { faAtolLogoNumber } from '@awesome.me/kit-d4c82d9167/icons/kit/custom';


const WhyCruiseNation = ({ }) => {
  return (
    <div className="page-section">
      <div className="container container--expanded">
        <h2 className="section-header">Why Cruise Nation?</h2>
        <div className={styles.columns}>
          <div className={styles.column}>
            <FontAwesomeIcon icon={faTrophy as IconProp} size="3x" />
            <p>
              Voted the UK&apos;s number 1 cruise holiday company - over 720,000 can&apos;t be wrong
            </p>
          </div>
          <div className={styles.column}>
            <FontAwesomeIcon icon={faSmile as IconProp} size="3x" />
            <p>
              By booking direct with us, you can save between 30% - 50% on the cruise package price
            </p>
          </div>
          <div className={styles.column}>
            <FontAwesomeIcon icon={faIslandTreePalm as IconProp} size="3x" />
            <p>
              All our smart holidays include free stays so you can get to discover your destination before hand
            </p>
          </div>
          <div className={styles.column}>
            <FontAwesomeIcon icon={faAtolLogoNumber as IconProp} size="3x" />
            <p>
              All holidays are fully ATOL protected. We are members of ABTA, so your booking is safe
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyCruiseNation;