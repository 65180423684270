import React from 'react'
import styles from './ThingsToDo.module.css'
import { ThingsToDoStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc"
import PageSection from '../../globals/page_section'
import HorizontalScrollView from '../../globals/carousels/custom_carousel'
import ThingsToDoItem from './things_to_do_item'
import { motion } from 'framer-motion'

const ThingsToDo = ({ blok }: { blok: ThingsToDoStoryblok }) => {
  return (
    <PageSection>
      <div {...storyblokEditable(blok)}>
        {
          blok.header ?
            <motion.h2 className='section-header'
              {...{
                initial: { opacity: 0, y: 25 },
                whileInView: { opacity: 1, y: 0 },
                viewport: { once: true, margin: "-100px" },
                transition: { duration: 0.6, ease: "easeInOut" }
              }}>
              {blok.header}
            </motion.h2>
            : null
        }
        <HorizontalScrollView className={styles.horizontalScroll}>
          {blok.slides.map((slide, index) => (
            <ThingsToDoItem height={blok.height} blok={slide} index={index} key={index} />
          ))}
        </HorizontalScrollView>
      </div>
    </PageSection>
  )
}

export default ThingsToDo