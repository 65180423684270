import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AlertDialog, AlertDialogContent } from "../alert-dialog/AlertDialog";
import Button from "../button";
import styles from './Navigation.module.css';

interface SmartCodeModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const SmartCodeModal = ({ isOpen, onOpenChange }: SmartCodeModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [inputState, setInputState] = useState<'empty' | 'valid' | 'invalid'>('empty');

  const isValid = (value: string): boolean => {
    return value.length === 11 && value.replace(/[A-Z]{3}\d{7}[CIS]/g, '').length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.toUpperCase();

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    if (value === '') {
      setInputState('empty');
    } else if (isValid(value)) {
      setInputState('valid');
    } else {
      setInputState('invalid');
    }

    setInputValue(value);
  };

  const goToCruise = (value: string) => {
    const isQuoteReference = value[value.length - 1] === 'I';
    const parsedValue = parseInt(value.replace(/\D/g, '') || '0', 10);

    const url = isQuoteReference
      ? `https://cruisenation.com/fusion/gotocruise3.pl?sid=8218&pid=${parsedValue}`
      : `https://cruisenation.com/holiday/${value}`;

    window.location.href = url;
  };

  const handleSubmit = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (isValid(inputValue)) {
      goToCruise(inputValue);
      onOpenChange(false);
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent className={styles.smartCodeModalContent}>
        <div className={styles.smartCodeModalContentTop}>
          <h3>Smart Code Search</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className={`${styles.smartCodeInput} ${styles[inputState]}`}
              placeholder="ENTER YOUR SMART CODE HERE"
              value={inputValue}
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              variant='primary'
              className={styles.smartCodeSubmit}
              disabled={!isValid(inputValue)}
            >
              LOOK-UP
            </Button>
          </form>

          <Button
            variant='transparent'
            onClick={() => onOpenChange(false)}
            className={styles.alertDialogClose}
          >
            <FontAwesomeIcon
              size='2x'
              icon={faXmark as IconProp}
              className={styles.alertDialogCloseIcon}
            />
          </Button>
        </div>
        <div className={styles.smartCodeModalFooter}>
          <p className={styles.smartCodeDescription}>
            <strong>What is a smart code?</strong><br />
            A unique identifier assigned to each cruise Holiday, allowing for easy tracking and booking.
            You can provide this to or from a sales agent to quickly and easily find a particular Holiday.
            Smart Codes can be found on a product page at the top of the screen.
          </p>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SmartCodeModal;