"use client"
import { FirstFooterSectionStoryblok } from '@/app/types/component-types-sb'
import React from 'react'

type Props = {
  content: FirstFooterSectionStoryblok
}

const FirstFooterSection = ({ content }: Props) => {
  return (
    <div/>
  )
}

export default FirstFooterSection