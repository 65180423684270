import { TabbedFeaturedDealsStoryblok, TabbedFeaturedDealStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React, { useEffect, useState } from 'react'
import styles from './TabbedFeaturedDeals.module.css'
import Container from '../../globals/container';
import PageSection from '../../globals/page_section';
import Button from '../../globals/button';
import TabbedFeaturedDeal from './featured_deal';
import { generate1DArray } from '@/utils/actions';
import clsx from 'clsx';

const TabbedFeaturedDeals = ({ blok }: { blok: TabbedFeaturedDealsStoryblok }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [focused, setFocused] = useState<TabbedFeaturedDealStoryblok | undefined>(undefined);

  useEffect(() => {
    if (blok.groups) {
      setFocused(blok.groups[currentTab]);
    }
  }, [currentTab]);

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h2 className="section-header">{blok.title}</h2>
          <div className={styles.topContainer}>
            {blok.groups && blok.groups.map((group, index) => (
              <Button variant={currentTab === index ? 'primary' : 'outline'} onClick={() => setCurrentTab(index)} key={index}>
                {group.title}
              </Button>
            ))}
          </div>
          {focused ? (
            <TabbedFeaturedDeal blok={focused} currentTab={currentTab} />
          ) : (
            <div className={styles["grid-container"]}>
              {generate1DArray(3, 4).map((row, rowIndex) => (
                <div key={rowIndex} className={clsx(styles["grid-item"], styles["shimmer"])}></div>
              ))}
            </div>
          )}
        </Container>
      </PageSection>
    </div>
  )
}

export default TabbedFeaturedDeals