import { FullSizeCarouselSlideStoryblok, FullSizeCarouselStoryblok } from '@/app/types/component-types-sb';
import { storyblokEditable } from '@storyblok/react';
import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';
import Image from '../../globals/image';
import CustomLink from '../../globals/link';
import styles from './FullSizeCarousel.module.css';
import SearchBar from '../../globals/search-bar';

const FullSizeCarousel = ({ blok }: { blok: FullSizeCarouselStoryblok }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'center'
  });

  return (
    <div {...storyblokEditable(blok)} className={styles.outterContainer}>
      <div className={styles.searchBarContainer}>
        <div className='container container-expanded'>
          <SearchBar/>
        </div>
      </div>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {
            blok.slides?.map((nestedBlok: FullSizeCarouselSlideStoryblok, index) => (
              <div key={index} className={styles.slide}>
                <div className={styles.overlay}>
                </div>
                <CustomLink link={nestedBlok.link}>
                  <Image asset={nestedBlok.background || ''} />
                  <Image asset={nestedBlok.foreground || ''} className={`${styles.foreground} container container--expanded`} />
                </CustomLink>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default FullSizeCarousel;