"use client"
import { AssetStoryblok, ThirdFooterSectionStoryblok } from '@/app/types/component-types-sb'
import styles from '../Footer.module.css';
import React from 'react'
import Container from '@/app/components/globals/container';
import CustomLink from '@/app/components/globals/link';
import Image from '@/app/components/globals/image';
import { render } from 'storyblok-rich-text-react-renderer';

type Props = {
  content: ThirdFooterSectionStoryblok
}

const ThirdFooterSection = ({ content }: Props) => {
  return (
    <div className={styles.thirdFooter}>
      <Container>
        <div className={styles.thirdFooterContainer}>
          <div className={styles.footerImage}>
            <CustomLink link={content.image_link[0].link} rel={content.image_link[0].relationship} target={content.image_link[0].opens_new_tab ? '_blank' : undefined} storyBlokLink>
              <Image asset={content.section_image as AssetStoryblok}
                style={{
                  borderRadius: '3px'
                }}
                className={styles.abtaAtol}
                size={{
                  width: 200,
                  height: 100
                }} />
            </CustomLink>
          </div>
          <div className={styles.footerText}>
            {render(content.content)}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ThirdFooterSection