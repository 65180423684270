
import { formatDate } from "@/utils/configs";
import getOrdinal from "./getOrdinal";

const getOfferDateFormat = (date: string) => {
  const monthYear = formatDate(date).format('MMM YYYY');
  const day = formatDate(date).date();
  const dayPlusOrdinal = getOrdinal(day);
  return `${dayPlusOrdinal} ${monthYear}`;
}

export default getOfferDateFormat;