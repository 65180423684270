import React, { useState, useRef } from "react";
import { storyblokEditable } from "@storyblok/react";
import styles from './FAQ.module.css';
import { render } from "storyblok-rich-text-react-renderer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAdd, faSubtract } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { FaqItemStoryblok } from "@/app/types/component-types-sb";

const FAQItem = ({ blok }: { blok: FaqItemStoryblok }) => {
  const [open, setOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <li className={styles.item} {...storyblokEditable(blok)}>
      <p className={styles.itemToggle} onClick={() => setOpen(!open)}>
        {blok.question}
        <FontAwesomeIcon 
          icon={(open ? faSubtract : faAdd) as IconProp} 
          className={`${styles.itemToggleIcon} ${open ? styles.iconRotate : ''}`} 
        />
      </p>
      <div 
        ref={contentRef}
        className={`${styles.answerWrapper} ${open ? styles.open : ''}`}
        style={{ 
          maxHeight: open ? `${contentRef.current?.scrollHeight}px` : '0',
        }}
      >
        <div className={`${styles.answer} rich-text-content`}>
          {render(blok.answer)}
        </div>
      </div>
    </li>
  );
};

export default FAQItem;