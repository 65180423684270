import React from 'react'
import styles from '../Footer.module.css';
import { FooterLinkColumnStoryblok } from '@/app/types/component-types-sb';
import CustomLink from '@/app/components/globals/link';
import Image from '@/app/components/globals/image';

type Props = {
  item: FooterLinkColumnStoryblok
}

const FooterGridItem = ({ item }: Props) => {
  return (
    <div className={styles.footerLinks}>
      <div>
        {(item.featured_link_background_image?.filename && item.featured_link?.cached_url) ? (
          <div className={styles.footerLinkImage}>
            <CustomLink link={item.featured_link} storyBlokLink>
              <Image asset={item.featured_link_background_image}
                style={{
                  borderRadius: '3px'
                }}
                size={{
                  width: 400,
                  height: 100
                }}
              />
            </CustomLink>
          </div>
        ) : (
          <p className={styles.footerLinkLabel}>{item.featured_link_override_label}</p>
        )}
        {item.links?.map((link) => (
          <CustomLink
            key={link._uid}
            image={link.icon}
            prefetch={false}
            override_label={link.override_label}
            link={link}
            className={styles.footerLink}
          />
        ))}
      </div>
    </div>
  )
}

export default FooterGridItem