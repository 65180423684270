import { WhatsIncludedItemStoryblok, WhatsIncludedSectionStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React, { useEffect, useState } from 'react'
import PageSection from '../../globals/page_section';
import styles from './WhatsIncluded.module.css';
import Container from '../../globals/container';
import { getWhatsIncluded } from '@/utils/queries';
import { WhatsIncludedType } from '@/app/types/lambda-types';
import WhatsIncludedItem from './WhatsIncludedItem';
import { motion } from 'framer-motion';

const WhatsIncluded = ({ blok }: { blok: WhatsIncludedSectionStoryblok }) => {

  const [whatsIncluded, setWhatsIncluded] = useState<WhatsIncludedType[] | WhatsIncludedItemStoryblok[]>([])

  useEffect(() => {

    if (blok.enter_manually) {
      setWhatsIncluded(blok.items || [])
      return;
    }

    const fetchData = async () => {
      const response = await getWhatsIncluded(
        {
          cruise_line_tt_id: parseInt(blok.cruise_lines || '1'),
          has_flights: blok.has_flights ? 1 : 0,
          has_hotels: blok.has_hotels ? 1 : 0
        }
      )
      console.log(response)
      setWhatsIncluded(response)
    }

    fetchData()
  }, [])

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <motion.h2
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className={styles.title}
          >
            {blok.title}
          </motion.h2>
          <div className={styles.whatsIncluded}>
            {whatsIncluded.map((item, index) => (
              <motion.div
                {...{
                  initial: { opacity: 0, y: 25 },
                  whileInView: { opacity: 1, y: 0 },
                  viewport: { once: true },
                  transition: { duration: 0.6, delay: Math.min(-0.075 + (0.075 * index), 0.6), ease: "easeInOut" }
                }}
                key={index}
              >
                <WhatsIncludedItem blok={item} />
              </motion.div>
            ))}
          </div>
        </Container>
      </PageSection>
    </div>
  )
}

export default WhatsIncluded