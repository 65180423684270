import { KnowledgeBaseHeaderStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from "./Header.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faArrowRight } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import React, { useState } from 'react'
import Image from '@/app/components/globals/image';
import Container from '@/app/components/globals/container';
import TextField from '@/app/components/globals/textfield';
import Button from '@/app/components/globals/button';
import { knowledgeBaseSearchFilter } from '@/utils/queries';
import { Article, KnowledgeBaseHeaderSearchFilter } from '@/app/types/lambda-types';
import removeMd from 'remove-markdown';
import SearchFilterDisplay from './SearchFilterDisplay';
import { useRouter } from 'next/navigation';


const FullHeader = ({ blok }: { blok: KnowledgeBaseHeaderStoryblok }) => {
    const [searchContent, setSearchContent] = useState<KnowledgeBaseHeaderSearchFilter>();
    const [search, setSearch] = useState('');
    const router = useRouter()
  
  
    const generateSubstringFromArticle = (article: Article, value: string) => {
      const descriptionWithoutMarkdown = removeMd(article.description);
      const indexOfSearch = descriptionWithoutMarkdown.toLowerCase().indexOf(value.toLowerCase());
      const substring = descriptionWithoutMarkdown.substring(indexOfSearch, indexOfSearch + value.length);
      const description = "..." + descriptionWithoutMarkdown.substring(indexOfSearch - 45, indexOfSearch) +
        `<span style="font-weight: bolder">${substring}</span>` +
        descriptionWithoutMarkdown.substring(indexOfSearch + value.length, indexOfSearch + 45) + "...";
  
        article.description = description
  
      return article;
    }
  
  
    const onSearch = async (value: string) => {
      const response = await knowledgeBaseSearchFilter(value);
      setSearchContent(response);
      setSearch(value);
    }

    const onSearchResults = async (value: string) => {
      router.push(`/knowledge-base/search/${value}`);
    }
  
  
    return (
      <div {...storyblokEditable(blok)} >
        <div className={styles.main}>
          <div className={styles.imageWrapper}>
            {blok.background_image ?
              <Image alt={blok.title} lazyLoad={false} asset={blok.background_image} /> : null
            }
          </div>
          <div className={styles.overlay} />
          <Container className={styles.container}>
            <h1 className={styles.title}>{blok.title}</h1>
            <p className={styles.subtitle}>{blok.subtitle}</p>
            <div className={styles.mainSearch}>
              <TextField
                placeholder="Have a question? Search here"
                searchable
                icon={faSearch}
                onSearch={onSearch}
                onKeyUp={e => e.key === 'Enter' && onSearchResults(search)}
                options={
                  searchContent?.articles?.map((article) => (
                    <SearchFilterDisplay
                      key={article.id}
                      article={generateSubstringFromArticle(article, search)}
                    />
                  ))
              }
                className={styles.search}
              />
              <Button className={styles.searchButton}>
                Search
                <FontAwesomeIcon icon={faArrowRight as IconProp} />
              </Button>
            </div>
          </Container>
        </div>
      </div>
    )
}

export default FullHeader