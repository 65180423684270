import React, { useState } from 'react'
import styles from '../DestinationHightlight.module.css';
import { storyblokEditable } from "@storyblok/react/rsc"
import { DestinationHighlightStoryblok } from '@/app/types/component-types-sb';
import { render } from 'storyblok-rich-text-react-renderer';
import Image from '@/app/components/globals/image';
import Button from '@/app/components/globals/button';
import {
    AlertDialog,
    AlertDialogTrigger,
    AlertDialogContent,
} from '@/app/components/globals/alert-dialog/AlertDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/regular"

const DestinationHightlight = ({ blok }: { blok: DestinationHighlightStoryblok }) => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <div {...storyblokEditable(blok)} key={blok._uid}>
            <div className={styles.highlight}>
                <Image
                    asset={blok.background_image ?? ''}
                    size={{
                        width: 460,
                        height: 580
                    }}
                />
                <div className={styles.imageBackground} />

                {/* Initial title that fades out on hover */}
                <h3 className={styles.title}>{blok.title}</h3>

                {/* Content that appears on hover */}
                <div className={styles.content}>
                    <h3 className={styles.contentTitle}>{blok.title}</h3>
                    <div className={styles.previewDescription}>
                        {render(blok.description)}
                    </div>

                    {/* Modal Dialog */}
                    <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
                        <AlertDialogTrigger asChild>
                            <Button
                                variant='outline'
                                className={styles.button}
                                color='white'
                            >
                                Read More
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent showBackdrop={false}>
                            <div className={styles.alertDialogContent}>
                                <div className={styles.alertDialogMain}>
                                    {/* Modal Background Image */}
                                    <div className={styles.highlightImage}>
                                        <Image
                                            className={styles.higlightBackgroundOverlay}
                                            asset={blok.background_image ?? ''}
                                            size={{
                                                width: 1280,
                                                height: 720
                                            }}
                                        />
                                    </div>
                                    <div className={styles.overlay} />

                                    {/* Modal Content */}
                                    <div className={styles.alertDialogInformation}>
                                        <h1 className={styles.highlightDialogTitle}>
                                            {blok.title}
                                        </h1>
                                        {blok.sub_title && (
                                            <p className={styles.alertSubTitle}>
                                                {blok.sub_title}
                                            </p>
                                        )}
                                        <div className={styles.alertDescription}>
                                            {render(blok.description)}
                                        </div>
                                    </div>

                                    {/* Close Button */}
                                    <Button
                                        variant='transparent'
                                        onClick={() => setShowDialog(false)}
                                        className={styles.alertDialogClose}
                                    >
                                        <FontAwesomeIcon
                                            size='3x'
                                            icon={faXmark as IconProp}
                                            className={styles.alertDialogCloseIcon}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            </div>
        </div>
    )
}

export default DestinationHightlight